import React, { useState } from 'react'
import "./GcpTernaryOpe.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const GcpTernaryOpe = () => {

    const [display, setDisplay] = useState("IaaS");

    const [btn1, setBtn1] = useState("newStyle");

    const [btn2, setBtn2] = useState("btnStyle");

    const [btn3, setBtn3] = useState("btn3Style");

    const changleDisplay = () => {
        setDisplay("IaaS")
        setBtn1("newStyle")
        setBtn2("btnStyle")
        setBtn3("btnStyle")
    }

    const changleDisplay1 = () => {
        setDisplay("PaaS")
        setBtn1("btnStyle")
        setBtn2("newStyle")
        setBtn3("btnStyle")
    }

    const changleDisplay3 = () => {
        setDisplay("SaaS")
        setBtn3("newStyle")
        setBtn1("btnStyle")
        setBtn2("btnStyle")
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/TernaryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className="container-lg  Dosis pb-4 ">
                        <div className="row d-flex justify-content-center align-items-center mb-lg-5">
                            <div className='OurHostingServices'>
                                <h2>
                                    Our Cloud Service models
                                </h2>
                                <p>
                                    The various cloud service models is essential for organizations
                                    looking to leverage the benefits of cloud technology. We will delve
                                    into the different cloud service models - Infrastructure as a Service
                                    (IaaS), Platform as a Service (PaaS), and Software as a Service
                                    (SaaS) - exploring their features, use cases, and comparing their advantages
                                    and challenges. Additionally, we will discuss the evolution of cloud technology,
                                    future trends, and the impact of cloud computing on the IT landscape.
                                </p>
                            </div>
                            <div className="row g-3 d-flex justify-content-center "  >
                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center " >
                                    <button className={btn1} onClick={changleDisplay}>IaaS</button>
                                </div>

                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center "  >
                                    <button className={btn2} onClick={changleDisplay1}>PaaS</button>
                                </div>

                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center "  >
                                    <button className={btn3} onClick={changleDisplay3}>SaaS</button>
                                </div>
                            </div>

                            <div >
                                {

                                    display === "IaaS" ?
                                        <div className="container-lg p-2">
                                            <div className='row d-flex justify-content-center '>
                                                <div className="col-md-10">
                                                    <div className="row g-4 GcpTitleSlider">
                                                        <h3>
                                                            IaaS
                                                        </h3>
                                                        <p>
                                                            IaaS stands for Infrastructure as a Service, which provides on-demand
                                                            access to scalable computing resources over the internet,
                                                            eliminating the need for companies to manage their own infrastructure
                                                        </p>

                                                        <Carousel responsive={responsive}
                                                            autoPlay={true}
                                                            swipeable={true}
                                                            draggable={true}
                                                            // showDots={true}
                                                            infinite={true}
                                                            partialVisible={false}
                                                        >

                                                            <div>
                                                                <div className='GcpCommonMain'>
                                                                    <img
                                                                        className="GcpCommon img-fluid mx-auto d-block"
                                                                        src={require("../Img/Iaas1.png")}
                                                                        alt="Cloud Storage" title='Cloud_Storage'
                                                                    />
                                                                    <h2>
                                                                        Cloud Storage
                                                                    </h2>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='GcpCommonMain'>
                                                                    <img
                                                                        className="GcpCommon img-fluid  text-center"
                                                                        src={require("../Img/Iaas2.png")}
                                                                        alt="ComputeEngine" title='ComputeEngine'
                                                                    />
                                                                    <h2>
                                                                        Compute Engine
                                                                    </h2>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='GcpCommonMain'>
                                                                    <img
                                                                        className="GcpCommon img-fluid  text-center"
                                                                        src={require("../Img/Iaas3.png")}
                                                                        alt="Networking" title='Networking'
                                                                    />
                                                                    <h2>
                                                                        Networking
                                                                    </h2>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='GcpCommonMain'>
                                                                    <img
                                                                        className="GcpCommon img-fluid  text-center"
                                                                        src={require("../Img/Iaas4.png")}
                                                                        alt="VPN" title='VPN'
                                                                    />
                                                                    <h2>
                                                                        VPN
                                                                    </h2>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='GcpCommonMain'>
                                                                    <img
                                                                        className="GcpCommon img-fluid mx-auto d-block"
                                                                        src={require("../Img/Iaas5.png")}
                                                                        alt="Kubernetes" title='Kubernetes'
                                                                    />
                                                                    <h2>
                                                                        Kubernetes
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </Carousel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        : display === "PaaS" ?
                                            <div className="container-lg p-2" >
                                                <div className='row d-flex justify-content-center '>
                                                    <div className="col-md-10">
                                                        <div className="row g-4 GcpTitleSlider">
                                                            <h3>
                                                                PaaS
                                                            </h3>
                                                            <p>
                                                                PaaS provides auto-managed infrastructure for hosting applications,
                                                                offering scalability and flexibility,
                                                                while simplifying hardware and software setup and management infrastructure
                                                            </p>

                                                            <Carousel responsive={responsive}
                                                                autoPlay={true}
                                                                swipeable={true}
                                                                draggable={true}
                                                                // showDots={true}
                                                                infinite={true}
                                                                partialVisible={false}
                                                            >

                                                                <div>
                                                                    <div className='GcpCommonMain'>
                                                                        <img
                                                                            className="GcpCommon img-fluid mx-auto d-block"
                                                                            src={require("../Img/PaaS1.png")}
                                                                            alt="AppEngine" title='AppEngine'
                                                                        />
                                                                        <h2>
                                                                            App Engine
                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className='GcpCommonMain'>
                                                                        <img
                                                                            className="GcpCommon img-fluid  text-center"
                                                                            src={require("../Img/PaaS2.png")}
                                                                            alt="CloudSQL" title='CloudSQL'
                                                                        />
                                                                        <h2>
                                                                            Cloud SQL
                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className='GcpCommonMain'>
                                                                        <img
                                                                            className="GcpCommon img-fluid  text-center"
                                                                            src={require("../Img/PaaS3.png")}
                                                                            alt="Bigquery" title='Bigquery'
                                                                        />
                                                                        <h2>
                                                                            Bigquery
                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className='GcpCommonMain'>
                                                                        <img
                                                                            className="GcpCommon img-fluid  text-center"
                                                                            src={require("../Img/PaaS4.png")}
                                                                            alt="Bigtable" title='Bigtable'
                                                                        />
                                                                        <h2>
                                                                            Bigtable
                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className='GcpCommonMain'>
                                                                        <img
                                                                            className="GcpCommon img-fluid mx-auto d-block"
                                                                            src={require("../Img/PaaS5.png")}
                                                                            alt="CloudDatastore" title='CloudDatastore'
                                                                        />
                                                                        <h2>
                                                                            Cloud Datastore
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </Carousel>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            : display === "SaaS" ?
                                                <div className="container-lg p-2" >
                                                    <div className='row d-flex justify-content-center '>
                                                        <div className="col-md-10">
                                                            <div className="row g-4 GcpTitleSlider">
                                                                <h3>
                                                                    SaaS
                                                                </h3>
                                                                <p>
                                                                    Software as a Service (SaaS) is a cloud computing model in which
                                                                    a third-party provider hosts applications
                                                                    and makes them available to customers over the internet.
                                                                </p>

                                                                <Carousel responsive={responsive}
                                                                    autoPlay={true}
                                                                    swipeable={true}
                                                                    draggable={true}
                                                                    // showDots={true}
                                                                    infinite={true}
                                                                    partialVisible={false}
                                                                >

                                                                    <div>
                                                                        <div className='GcpCommonMain'>
                                                                            <img
                                                                                className="GcpCommon img-fluid mx-auto d-block"
                                                                                src={require("../Img/SaaS1.png")}
                                                                                alt="SalesCloud" title='SalesCloud'
                                                                            />
                                                                            <h2>
                                                                                Sales Cloud
                                                                            </h2>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div className='GcpCommonMain'>
                                                                            <img
                                                                                className="GcpCommon img-fluid  text-center"
                                                                                src={require("../Img/SaaS2.png")}
                                                                                alt="AppEngine" title='AppEngine'
                                                                            />
                                                                            <h2>
                                                                                App Engine
                                                                            </h2>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div className='GcpCommonMain'>
                                                                            <img
                                                                                className="GcpCommon img-fluid  text-center"
                                                                                src={require("../Img/SaaS3.png")}
                                                                                alt="CloudStorage" title='CloudStorage'
                                                                            />
                                                                            <h2>
                                                                                Cloud Storage
                                                                            </h2>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div className='GcpCommonMain'>
                                                                            <img
                                                                                className="GcpCommon img-fluid  text-center"
                                                                                src={require("../Img/SaaS4.png")}
                                                                                alt="VPN" title='VPN'
                                                                            />
                                                                            <h2>
                                                                                VPN
                                                                            </h2>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div className='GcpCommonMain'>
                                                                            <img
                                                                                className="GcpCommon img-fluid mx-auto d-block"
                                                                                src={require("../Img/SaaS5.png")}
                                                                                alt="MobileSoftware" title='MobileSoftware'
                                                                            />
                                                                            <h2>
                                                                                Mobile Software
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                </Carousel>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                : <div>
                                                    somethings went wrong
                                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default GcpTernaryOpe