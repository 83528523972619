import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Navbar from './Navbar'
import HomeSilder from './HomeSilder'
import TernaryOpertor from './TernaryOpertor'
import Review from './Review'
import Footer from './Footer'
import Contact from './Contact'
import Company from './Company'
import Error from './Error'
import GoogleCloudPlatform from './GoogleCloudPlatform'
import GcpTernaryOpe from './GcpTernaryOpe'
import GoogleWorkspace from './GoogleWorkspace'
import Aws from './Aws'
import Microsoft365 from './Microsoft365'
import MicrosoftDynamics365 from './MicrosoftDynamics365'
import MicrosoftAzure from './MicrosoftAzure'
import CloudMange from './CloudMange'
import CloudMigration from './CloudMigration'
import PrivateCloud from './PrivateCloud'
import DevOps from './DevOps'
import IoT from './IoT'
import CloudDisaster from './CloudDisaster'
import Acronis from './Acronis'
import Veeam from './Veeam'
import Login from './Login'
import Register from './Register'

const Routing = () => {
    return (
        <>

            <React.Fragment>
                <header >
                    <Navbar />
                </header>
                <main>
                    <Routes>
                        <Route exact to path='/' element={<Home />} />
                        <Route exact to path='/contact' element={<Contact />} /> 
                        <Route exact to path='/company' element={<Company />} />
                        <Route exact to path='/googlecloudplatform' element={<GoogleCloudPlatform />} />
                        <Route exact to path='/googleworkspace' element={<GoogleWorkspace />} />
                        <Route exact to path='/amazonwebservices' element={<Aws />} />
                        <Route exact to path='/microsoft365' element={<Microsoft365 />} />
                        <Route exact to path='/microsoftdynamics365' element={<MicrosoftDynamics365 />} />
                        <Route exact to path='/microsoftazure' element={<MicrosoftAzure />} />
                        <Route exact to path='/cloudmange' element={<CloudMange />} />
                        <Route exact to path='/cloudmigration' element={<CloudMigration />} />
                        <Route exact to path='/privatecloud' element={<PrivateCloud />} />
                        <Route exact to path='/devops' element={<DevOps />} />
                        <Route exact to path='/iot' element={<IoT />} />
                        <Route exact to path='/clouddisaster' element={<CloudDisaster />} />
                        <Route exact to path='/acronis' element={<Acronis />} />
                        <Route exact to path='/veeam' element={<Veeam />} />

                        <Route exact to path='/gcpternaryopertor' element={<GcpTernaryOpe />} />
                        <Route exact to path='/homesilder' element={<HomeSilder />} />
                        <Route exact to path='/ternaryopertor' element={<TernaryOpertor />} />
                        <Route exact to path='/review' element={<Review />} />

                        <Route exact to path='/login' element={<Login />} />
                        <Route exact to path='/register' element={<Register />} />

                        <Route exact to path='*' element={<Error />} />
                    </Routes>
                </main>
                <footer>
                    <Footer />
                </footer>
            </React.Fragment>

        </>
    )
}

export default Routing