import React, { useState } from 'react'
import "./TernaryOpertor.css"

const TernaryOpertor = () => {

    const [display, setDisplay] = useState("Gcp");

    const [btn1, setBtn1] = useState("newStyle");

    const [btn2, setBtn2] = useState("btnStyle");

    const [btn3, setBtn3] = useState("btn3Style");

    const [btn4, setBtn4] = useState("btn4Style");

    const [btn5, setBtn5] = useState("btn5Style");

    const changleDisplay = () => {
        setDisplay("Gcp")
        setBtn1("newStyle")
        setBtn2("btnStyle")
        setBtn3("btnStyle")
        setBtn4("btnStyle")
        setBtn5("btnStyle")
    }

    const changleDisplay1 = () => {
        setDisplay("Aws")
        setBtn1("btnStyle")
        setBtn2("newStyle")
        setBtn3("btnStyle")
        setBtn4("btnStyle")
        setBtn5("btnStyle")
    }

    const changleDisplay3 = () => {
        setDisplay("Microsoft")
        setBtn3("newStyle")
        setBtn1("btnStyle")
        setBtn2("btnStyle")
        setBtn4("btnStyle")
        setBtn5("btnStyle")
    }

    const changleDisplay4 = () => {
        setDisplay("Hybrid Cloud")
        setBtn4("newStyle")
        setBtn2("btnStyle")
        setBtn1("btnStyle")
        setBtn3("btnStyle")
        setBtn5("btnStyle")
    }

    const changleDisplay5 = () => {
        setDisplay("Backup")
        setBtn5("newStyle")
        setBtn2("btnStyle")
        setBtn1("btnStyle")
        setBtn3("btnStyle")
        setBtn4("btnStyle")
    }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/TernaryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className="container-lg Dosis pb-5 ">
                        <div className="row d-flex justify-content-center align-items-center mb-lg-5">
                            <div className='OurHostingServices'>
                                <h2>
                                    Our Hosting Services
                                </h2>
                                <p>
                                    Reliable, secure, and fast hosting solutions for your website. 99.9% uptime guarantee and expert 24/7 support. <br />
                                    Scalable plans to suit your growing needs
                                </p>
                            </div>
                            <div className="row g-3 d-flex justify-content-center "  >
                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center " >
                                    <button className={btn1} onClick={changleDisplay}>GCP</button>
                                </div>

                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center "  >
                                    <button className={btn2} onClick={changleDisplay1}>AWS</button>
                                </div>

                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center "  >
                                    <button className={btn3} onClick={changleDisplay3}>Microsoft</button>
                                </div>

                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center "  >
                                    <button className={btn4} onClick={changleDisplay4}>Hybrid Cloud</button>
                                </div>

                                <div className="col-lg-2 col-md-4 col-4 d-flex justify-content-center "  >
                                    <button className={btn5} onClick={changleDisplay5}>Backup</button>
                                </div>

                            </div>

                            <div >
                                {

                                    display === "Gcp" ?
                                        <div className="container-lg " >
                                            <div className='row d-flex justify-content-center '>
                                                <div className="col-md-10">
                                                    <div className="row g-4 MainTernory">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <img className='w-100' src={require("../Img/Google-Cloud-Platform.jpg")} alt='Google-Cloud-Platform' title='Google-Cloud-Platform' />
                                                        </div>

                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className='TernaryTitle'>
                                                                <h2>
                                                                    Google Cloud Platform
                                                                </h2>
                                                                <div className='detail-wrapper'>
                                                                    <ul className='detail'>
                                                                        <li>
                                                                            <i className='fa fa-angle-right'></i>
                                                                            Dedicated IP
                                                                        </li>
                                                                        <li>
                                                                            <i className='fa fa-angle-right'></i>
                                                                            SSL Certificate
                                                                        </li>
                                                                        <li>
                                                                            <i className='fa fa-angle-right'></i>
                                                                            Domain Privacy
                                                                        </li>
                                                                        <li>
                                                                            <i className='fa fa-angle-right'></i>
                                                                            Site Backup Pro
                                                                        </li>
                                                                    </ul>

                                                                    <ul className='detail'>
                                                                        <li>
                                                                            <i className='fa fa-angle-right'></i>
                                                                            Renewal Price
                                                                        </li>
                                                                        <li>
                                                                            <i className='fa fa-angle-right'></i>
                                                                            Available CPUs
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <p>
                                                            The web is the most powerful resource
                                                            on the planet, and we're here to help you harness it.
                                                            Cloud Sites includes our renowned 24/7 support so you
                                                            can always get the help you need, right when you need it.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        : display === "Microsoft" ?
                                            <div className="container-lg " >
                                                <div className='row d-flex justify-content-center '>
                                                    <div className="col-md-10">
                                                        <div className="row g-4 MainTernory">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <img className='w-100' src={require("../Img/Microsoft.jpg")} alt='Microsoft' title='Microsoft' />
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className='TernaryTitle'>
                                                                    <h2>
                                                                        Microsoft 365
                                                                    </h2>
                                                                    <div className='detail-wrapper'>
                                                                        <ul className='detail'>
                                                                            <li>
                                                                                <i className='fa fa-angle-right'></i>
                                                                                Dedicated IP
                                                                            </li>
                                                                            <li>
                                                                                <i className='fa fa-angle-right'></i>
                                                                                SSL Certificate
                                                                            </li>
                                                                            <li>
                                                                                <i className='fa fa-angle-right'></i>
                                                                                Domain Privacy
                                                                            </li>
                                                                            <li>
                                                                                <i className='fa fa-angle-right'></i>
                                                                                Site Backup Pro
                                                                            </li>
                                                                        </ul>

                                                                        <ul className='detail'>
                                                                            <li>
                                                                                <i className='fa fa-angle-right'></i>
                                                                                Renewal Price
                                                                            </li>
                                                                            <li>
                                                                                <i className='fa fa-angle-right'></i>
                                                                                Available CPUs
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <p>
                                                                The web is the most powerful resource
                                                                on the planet, and we're here to help you harness it.
                                                                Cloud Sites includes our renowned 24/7 support so you
                                                                can always get the help you need, right when you need it.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            : display === "Aws" ?
                                                <div className="container-lg " >
                                                    <div className='row d-flex justify-content-center '>
                                                        <div className="col-md-10">
                                                            <div className="row g-4 MainTernory">
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <img className='w-100' src={require("../Img/Amazon-Web-Services.jpg")} alt='Amazon-Web-Services' title='Amazon-Web-Services' />
                                                                </div>

                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <div className='TernaryTitle'>
                                                                        <h2>
                                                                            Amazon Web Services
                                                                        </h2>
                                                                        <div className='detail-wrapper'>
                                                                            <ul className='detail'>
                                                                                <li>
                                                                                    <i className='fa fa-angle-right'></i>
                                                                                    Dedicated IP
                                                                                </li>
                                                                                <li>
                                                                                    <i className='fa fa-angle-right'></i>
                                                                                    SSL Certificate
                                                                                </li>
                                                                                <li>
                                                                                    <i className='fa fa-angle-right'></i>
                                                                                    Domain Privacy
                                                                                </li>
                                                                                <li>
                                                                                    <i className='fa fa-angle-right'></i>
                                                                                    Site Backup Pro
                                                                                </li>
                                                                            </ul>

                                                                            <ul className='detail'>
                                                                                <li>
                                                                                    <i className='fa fa-angle-right'></i>
                                                                                    Renewal Price
                                                                                </li>
                                                                                <li>
                                                                                    <i className='fa fa-angle-right'></i>
                                                                                    Available CPUs
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <p>
                                                                    The web is the most powerful resource
                                                                    on the planet, and we're here to help you harness it.
                                                                    Cloud Sites includes our renowned 24/7 support so you
                                                                    can always get the help you need, right when you need it.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                : display === "Hybrid Cloud" ?
                                                    <div className="container-lg " >
                                                        <div className='row d-flex justify-content-center '>
                                                            <div className="col-md-10">
                                                                <div className="row g-4 MainTernory">
                                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                                        <img className='w-100' src={require("../Img/Hybrid-Cloud.jpg")} alt='HybridCloud' title='HybridCloud' />
                                                                    </div>

                                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                                        <div className='TernaryTitle'>
                                                                            <h2>
                                                                                Hybrid Cloud
                                                                            </h2>
                                                                            <div className='detail-wrapper'>
                                                                                <ul className='detail'>
                                                                                    <li>
                                                                                        <i className='fa fa-angle-right'></i>
                                                                                        Dedicated IP
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className='fa fa-angle-right'></i>
                                                                                        SSL Certificate
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className='fa fa-angle-right'></i>
                                                                                        Domain Privacy
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className='fa fa-angle-right'></i>
                                                                                        Site Backup Pro
                                                                                    </li>
                                                                                </ul>

                                                                                <ul className='detail'>
                                                                                    <li>
                                                                                        <i className='fa fa-angle-right'></i>
                                                                                        Renewal Price
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className='fa fa-angle-right'></i>
                                                                                        Available CPUs
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <p>
                                                                        The web is the most powerful resource
                                                                        on the planet, and we're here to help you harness it.
                                                                        Cloud Sites includes our renowned 24/7 support so you
                                                                        can always get the help you need, right when you need it.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : display === "Backup" ?
                                                        <div className="container-lg " >
                                                            <div className='row d-flex justify-content-center '>
                                                                <div className="col-md-10">
                                                                    <div className="row g-4 MainTernory">
                                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                                            <img className='w-100' style={{height:"19rem"}} src={require("../Img/VeemBackup.png")} alt='Backup' title='Backup' />
                                                                        </div>

                                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                                            <div className='TernaryTitle'>
                                                                                <h2>
                                                                                    Backup
                                                                                </h2>
                                                                                <div className='detail-wrapper'>
                                                                                    <ul className='detail'>
                                                                                        <li>
                                                                                            <i className='fa fa-angle-right'></i>
                                                                                            Dedicated IP
                                                                                        </li>
                                                                                        <li>
                                                                                            <i className='fa fa-angle-right'></i>
                                                                                            SSL Certificate
                                                                                        </li>
                                                                                        <li>
                                                                                            <i className='fa fa-angle-right'></i>
                                                                                            Domain Privacy
                                                                                        </li>
                                                                                        <li>
                                                                                            <i className='fa fa-angle-right'></i>
                                                                                            Site Backup Pro
                                                                                        </li>
                                                                                    </ul>

                                                                                    <ul className='detail'>
                                                                                        <li>
                                                                                            <i className='fa fa-angle-right'></i>
                                                                                            Renewal Price
                                                                                        </li>
                                                                                        <li>
                                                                                            <i className='fa fa-angle-right'></i>
                                                                                            Available CPUs
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <p>
                                                                            The web is the most powerful resource
                                                                            on the planet, and we're here to help you harness it.
                                                                            Cloud Sites includes our renowned 24/7 support so you
                                                                            can always get the help you need, right when you need it.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        : <div>
                                                            somethings went wrong
                                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TernaryOpertor