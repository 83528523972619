import React from 'react'
import "./Footer.css"

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid pt-lg-3  d-md-none d-none d-lg-block" style={{ backgroundColor: "#1b232f" }}>
                    <div className="container-lg p-md-4 laila-semibold pt-lg-4">
                        <div className="row p-lg-3 g-4 d-flex justify-content-center">
                            <div className="col-lg-4 col-md-5 text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-2 pb-1 Dosis' style={{ fontSize: "28px", fontWeight: "700" }} > iCloudsoft Technology </h6>
                                </div>

                                <div>
                                    {/* <p className=' mb-3' style={{ color: "#fff", fontSize: "17px", lineHeight: "27px", fontWeight: "400" }} >
                                        It is my duty to distract the reader by just looiking at it
                                    </p> */}
                                    <p className='mt-4'>
                                        <a href='tel:9527468898' className='text-decoration-none NumberHover' style={{ cursor: "pointer" }}> <h6 className='mb-3' style={{ fontWeight: "400" }}><i class="fa-solid fa-phone me-3"></i>+91 9527468898</h6></a>
                                    </p>
                                    <p>
                                        <a href='mailto:sales@icloudsoft.co.in' className='text-decoration-none EmailHover' style={{ cursor: "pointer" }} > <h6 className='mb-2 ' style={{ fontWeight: "400" }}><i class="fa-solid fa-envelope me-3"></i>sales@icloudsoft.co.in</h6></a>
                                    </p>
                                    <p className='d-flex mb-lg-3 pb-lg-3' >
                                        <span>
                                            <i class="fa-solid fa-location-dot"></i>
                                        </span>
                                        <span className='ms-3' style={{ fontWeight: "400", fontSize: "15px" }}>
                                            Office No 701, 7th Floor, Lotus Building, Opp Joshi Railway Museum
                                            Kothrud , Pune 411038
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-1"></div>

                            <div className="col-lg-2 col-md-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-3 ' style={{ fontSize: "24px", fontWeight: "800" }} >Solutions</h6>
                                </div>
                                <div className='mt-3 pt-2  ' style={{ color: "#fff", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                    <a href='/googlecloudplatform' className='text-decoration-none FooterHover' > <p className=''>GCP</p></a>
                                    <a href='/amazonwebservices' className='text-decoration-none FooterHover' > <p className=''>AWS</p></a>
                                    <a href='/microsoft365' className='text-decoration-none FooterHover' > <p className=''>Microsoft</p></a>
                                    <a href='/acronis' className='text-decoration-none FooterHover' > <p className=''>Backup</p></a>
                                    <a href='/cloudmange' className='text-decoration-none FooterHover'> <p className=''>Hybrid Cloud</p></a>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-3' style={{ fontSize: "24px", fontWeight: "800" }} >Support</h6>
                                </div>
                                <div className='mt-3 pt-2  ' style={{ color: "#fff", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>Chat</p></a>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>Tickets</p></a>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>Knowledge</p></a>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>System status</p></a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-3' style={{ fontSize: "24px", fontWeight: "800" }} >Company</h6>
                                </div>
                                <div className='mt-3 pt-2  ' style={{ color: "#fff", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                    <a href='/company' className='text-decoration-none FooterHover' > <p className=''>About</p></a>
                                    <a href='/contact' className='text-decoration-none FooterHover' > <p className=''>Contact</p></a>
                                    <a href='/' className='text-decoration-none FooterHover'> <p className=''>Terms & Conditions</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block " style={{ backgroundColor: "#1b232f" }}>
                    <div className="container-lg p-3 laila-semibold pt-4">
                        <div className="row p-3 g-2 d-flex justify-content-around">
                            <div className="col-lg-4 col-md-6  text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-3 Dosis' style={{ fontSize: "32px", fontWeight: "600" }} > iCloudsoft Technology </h6>
                                </div>
                                <div className='pt-1'>
                                    <p className='mt-4'>
                                        <a href='tel:9527468898' className='text-decoration-none NumberHover' style={{ cursor: "pointer" }}> <h6 className='mb-3' style={{ fontWeight: "400" }}><i class="fa-solid fa-phone me-3"></i>+91 9527468898</h6></a>
                                    </p>
                                    <p>
                                        <a href='mailto:sales@icloudsoft.co.in' className='text-decoration-none EmailHover' style={{ cursor: "pointer" }} > <h6 className='mb-2 ' style={{ fontWeight: "400" }}><i class="fa-solid fa-envelope me-3"></i>sales@icloudsoft.co.in</h6></a>
                                    </p>
                                    <p className='d-flex mb-lg-3 pb-lg-3' style={{ lineHeight: "30px" }} >
                                        <span>
                                            <i class="fa-solid fa-location-dot"></i>
                                        </span>
                                        <span className='ms-3' style={{ fontWeight: "400", fontSize: "14px" }}>
                                            Office No 701, 7th Floor, Lotus Building, Opp Joshi Railway Museum
                                            Kothrud , Pune 411038
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-3' style={{ fontSize: "24px", fontWeight: "800" }} >Solutions</h6>
                                </div>
                                <div className='mt-3 pt-2  ' style={{ color: "#fff", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                    <a href='/googlecloudplatform' className='text-decoration-none FooterHover' > <p className=''>GCP</p></a>
                                    <a href='/amazonwebservices' className='text-decoration-none FooterHover' > <p className=''>AWS</p></a>
                                    <a href='/microsoft365' className='text-decoration-none FooterHover' > <p className=''>Microsoft</p></a>
                                    <a href='/acronis' className='text-decoration-none FooterHover' > <p className=''>Backup</p></a>
                                    <a href='/cloudmange' className='text-decoration-none FooterHover'> <p className=''>Hybrid Cloud</p></a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-6 text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-3' style={{ fontSize: "24px", fontWeight: "800" }} >Support</h6>
                                </div>
                                <div className='mt-3 pt-2  ' style={{ color: "#fff", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>Chat</p></a>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>Tickets</p></a>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>Knowledge</p></a>
                                    <a href='/' className='text-decoration-none FooterHover' > <p className=''>System status</p></a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6  text-white ">
                                <div className='mb-2' >
                                    <h6 className=' text-decoration-none text-white mt-3' style={{ fontSize: "24px", fontWeight: "800" }} >Company</h6>
                                </div>
                                <div className='mt-3 pt-2  ' style={{ color: "#fff", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                    <a href='/company' className='text-decoration-none FooterHover' > <p className=''>About</p></a>
                                    <a href='/contact' className='text-decoration-none FooterHover' > <p className=''>Contact</p></a>
                                    <a href='/' className='text-decoration-none FooterHover'> <p className=''>Terms & Conditions</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#1b232f", borderTop: "1px solid #0b0f14" }}>
                    <div className="container-lg FooterBottomSection" >
                        <div className="d-flex justify-content-between align-items-center" >
                            <div className='' >
                                Copyright © iCloudsoft Technology 2024,Inc. All Rights Reserved. Various trademarks held by their respective owners.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer