import React, { useRef, useState } from 'react'
import "./Home.css"
import HomeSilder from './HomeSilder'
import TernaryOpertor from './TernaryOpertor'
import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger"
import Review from './Review';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {

    const [counterOn, setCounterOn] = useState(false);

    const form = useRef();

    const [email, setEmail] = useState("");
    const [droption, setDroption] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = ""; errors.droption = "";

            var url = "https://wa.me/9527468898?text="
                + " " + email + "" + droption;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");
        setDroption("");
    }

    const validateForm = () => {
        const errors = {};

        if (!email) {
            errors.email = "Email Is Requried";
        }
        // if (email) {
        //     if (email.match(mailformat)) {

        //     }
        //     else {
        //         errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
        //     }
        // }

        if (!droption) {
            errors.droption = "Option Is Requried";
        }

        return errors;

    }

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title> Cloud Solutions Provider in Pune | iCloudsoft Technology</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block" >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active  Same-Img-Size" style={{ backgroundImage: `url(${require("../Img/Slider1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top ' }}>
                                <div className='Main-Silder-Content'>
                                    <h1>
                                        Welcome to the india's best <br />
                                        cloud company
                                    </h1>
                                    <h6>
                                        The iCloudsoft Technology offers a range of innovative cloud storage <br />
                                        solutions designed to streamline your data management processes.
                                    </h6>
                                    <a href="/contact" className='text-decoration-none'>
                                        <button>
                                            Get Started
                                        </button>
                                    </a>
                                </div>
                            </div>

                            <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/Slider2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top' }}>
                                <div className='Main-Silder-Content'>
                                    <h2>
                                        Welcome to the india's best <br />
                                        cloud company
                                    </h2>
                                    <h6>
                                        The iCloudsoft Technology offers a range of innovative cloud storage <br />
                                        solutions designed to streamline your data management processes.
                                    </h6>
                                    <a href="/contact" className='text-decoration-none'>
                                        <button>
                                            Get Started
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block" >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active  Same-Img-Size" style={{ backgroundImage: `url(${require("../Img/Slider1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,top ' }}>
                                <div className='Main-Silder-Content'>
                                    <h2>
                                        Welcome to the india's best <br />
                                        cloud company
                                    </h2>
                                    <h6>
                                        The iCloudsoft Technology offers a range of <br /> innovative cloud storage
                                        solutions designed <br /> to streamline your data management processes.
                                    </h6>
                                </div>
                            </div>

                            <div className="carousel-item  Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/Slider2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,top' }}>
                                <div className='Main-Silder-Content'>
                                    <h2>
                                        Welcome to the india's best <br />
                                        cloud company
                                    </h2>
                                    <h6>
                                        The iCloudsoft Technology offers a range of <br /> innovative cloud storage
                                        solutions designed <br /> to streamline your data management processes.
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <HomeSilder />

            <section>
                <div className="container-fluid">
                    <div className="container-lg FeatureMain">
                        <div className='SolutionTop'>
                            <h2>
                                What makes Cloud Host the best hosting <br />
                                solution for you
                            </h2>
                        </div>
                        <div className="row mt-3 pb-3 g-4">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 ">
                                <div className='SolutionMain'>
                                    <div className=''>
                                        <img className='' src={require("../Img/HomeSecondSe1.png")} alt="HomeSecondSe1" title='HomeSecondSe1' />
                                    </div>
                                    <div className='Feature-Content'>
                                        <h2>
                                            100% Up time guranteed
                                        </h2>
                                        <p>
                                            Our product guarantees 100% uptime, providing you with a reliable and consistent performance
                                        </p>
                                        <a href="/googlecloudplatform" className='text-decoration-none'>
                                            <button type="button" className="text-decoration-none">See how it works
                                                <i class="fa-sharp fa-solid fa-angle-right"></i>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className='SolutionMain'>
                                    <div className=''>
                                        <img className='' src={require("../Img/HomeSecondSe2.png")} alt="HomeSecondSe2" title='HomeSecondSe2' />
                                    </div>
                                    <div className='Feature-Content'>
                                        <h2>
                                            Secure and Fast Loading
                                        </h2>
                                        <p>
                                            Elevate your data management experience with our Secure and
                                            Fast Loading Data on Cloud service
                                        </p>
                                        <a href="/amazonwebservices" className='text-decoration-none'>
                                            <button type="button" className="text-decoration-none">See how it works
                                                <i class="fa-sharp fa-solid fa-angle-right"></i>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className='SolutionMain'>
                                    <div className=''>
                                        <img className='' src={require("../Img/HomeSecondSe3.png")} alt="HomeSecondSe3" title='HomeSecondSe3' />
                                    </div>
                                    <div className='Feature-Content'>
                                        <h2>
                                            24/7 Dedicated Support
                                        </h2>
                                        <p>
                                            With our 24/7 Dedicated Support, you can rest easy knowing that
                                            help is always just a phone call or message away
                                        </p>
                                        <a href="/contact" className='text-decoration-none'>
                                            <button className="text-decoration-none">See how it works
                                                <i class="fa-sharp fa-solid fa-angle-right"></i>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='row g-4 d-flex align-items-center g-4  p-3'>
                            <div className='col-lg-8 '>
                                <div className='PackageTop'>
                                    <h2>
                                        Register Your Domain Now!
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="text" placeholder='ex:icloudsoft' className="form-control EmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>

                                    <div className="col-2">
                                        <div class="custome-select">
                                            <select
                                                onChange={e => setDroption(e.target.value)} value={droption}>
                                                <option>.com</option>
                                                <option>.net</option>
                                                <option>.org</option>
                                                <option>.us</option>
                                                <option>.in</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                                <p className='PackageBottom'>
                                    Are you ready to establish a strong online presence for your
                                    business, blog, or personal brand? Registering your domain is the first step towards creating a professional and memorable website. With our easy and efficient domain registration process,
                                    you can secure your perfect domain in just a few minutes.
                                </p>
                            </div>

                            <div className='col-lg-4 text-center'>
                                <span className='Discount '>
                                    <img className='' src={require("../Img/discount.png")} alt="discount" title='discount' />
                                </span>
                                <span className='Server'>
                                    <img className='' src={require("../Img/server.png")} alt="server" title='server' />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-2 Dosis'>
                        <div className='row g-4 d-flex align-items-center g-4  p-2'>
                            <div className='col-lg-8 '>
                                <div className='PackageTop'>
                                    <h2>
                                        Register Your Domain Now!
                                    </h2>
                                </div>
                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <input type="text" placeholder='ex: iCloudsoft' className="form-control EmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>

                                    <div className="col-lg-2 col-md-2 col-2">
                                        <div class="custome-select">
                                            <select
                                                onChange={e => setDroption(e.target.value)} value={droption}>
                                                <option >.com</option>
                                                <option>.net</option>
                                                <option>.org</option>
                                                <option>.us</option>
                                                <option>.in</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="FirstButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                                <p className='PackageBottom'>
                                    Are you ready to establish a strong online presence for your business,
                                    blog, or personal brand? Registering your domain is the first step towards
                                    creating a professional and memorable website. With our easy and efficient domain registration process,
                                    you can secure your perfect domain in just a few minutes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TernaryOpertor />

            <section>
                <div className='container-fluid' style={{ backgroundImage: `url(${require("../Img/counter-bg.png")})`, backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }} >
                    <div className="container-lg CounterMain " >
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <div className="row g-4 d-flex justify-content-center align-items-center  text-center">
                                <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                                    <div className=''>
                                        <img className='mb-2' src={require("../Img/Counter1.png")} alt="Counter1" title='Counter1' />
                                        <div className='CounterSize'> {counterOn && <CountUp start={0} end={8955} duration={2} delay={0}> </CountUp>}  + </div>
                                        <p className='CounterSizeSmall'> Satisfied client </p>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                                    <div>
                                        <img className='mb-2' src={require("../Img/Counter2.png")} alt="Counter2" title='Counter2' />
                                        <div className='CounterSize' > {counterOn && <CountUp start={0} end={1194} duration={2} delay={0}> </CountUp>}  </div>
                                        <p className='CounterSizeSmall'> Sold Domains </p>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                                    <div>
                                        <img className='mb-2' src={require("../Img/Counter3.png")} alt="Counter3" title='Counter3' />
                                        <div className='CounterSize' > {counterOn && <CountUp start={0} end={2180} duration={2} delay={0}> </CountUp>}</div>
                                        <p className='CounterSizeSmall' > Times Shared </p>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                                    <div>
                                        <img className='mb-2' src={require("../Img/Counter4.png")} alt="Counter4" title='Counter4' />
                                        <div className='CounterSize' > {counterOn && <CountUp start={0} end={9788} duration={2} delay={0}> </CountUp>}</div>
                                        <p className='CounterSizeSmall' > Hosted Sites </p>
                                    </div>
                                </div>
                            </div>
                        </ScrollTrigger>
                    </div>
                </div>
            </section>

            <Review />

        </>
    )
}

export default Home