import React, { useRef, useState } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";

const MicrosoftDynamics365 = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/microsoftdynamic365" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology ,Microsoft" />
                    <title>  Microsoft Partner | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Unify your business
                        </h2>
                        <h1>
                            Trusted Dynamics 365 partner in India.
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3> Driving Digital Transformation </h3>
                                    <p>
                                        Microsoft Dynamics 365 is a cloud-based ERP software that
                                        integrates seamlessly with Office 365 apps, providing a
                                        full range of business process improvements. This enables
                                        your business to improve internal efficiency, reinvent
                                        customer engagement, and enhance productivity through
                                        business intelligence. With Dynamics 365, you can market
                                        smarter, sell effectively, and interact and respond to
                                        customers from anywhere, all on a single platform.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Digital_Transformation' title='Digital_Transformation' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        <b>
                                            Dynamics 365 for Sales ,
                                        </b>
                                        &nbsp;
                                        sales teams can work smarter
                                        and close deals faster. Its predictive analytics, digital
                                        intelligence, and automated lead scoring enable personalized
                                        engagement with customers. The team can collaborate effectively
                                        with shared meeting notes, events, and custom sales documents
                                        created with real-time co-authoring. Social insights and up-to-date
                                        company information give them a competitive edge, while an embedded
                                        sales process helps them know how and when to engage. Easy-to-use
                                        dashboards and gamification enhance
                                        sales performance and provide true CRM capabilities.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic1.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg  pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic2.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        <b>
                                            Dynamics 365 for Customer Service
                                        </b>
                                        &nbsp;
                                        is a unified CRM platform
                                        that helps businesses offer world-class customer service,
                                        resulting in increased loyalty and more business. It provides
                                        agents with the necessary information across engagements to
                                        offer personalized and consistent service. Customers have
                                        access to a self-service portal with a searchable knowledge
                                        base and online community space. The data analytics in Customer Service
                                        also enable predictive and proactive customer service.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        Dynamics 365 for Customer Service is a unified CRM platform
                                        that helps businesses offer world-class customer service,
                                        resulting in increased loyalty and more business. It provides
                                        agents with the necessary information across engagements to
                                        offer personalized and consistent service. Customers have
                                        access to a self-service portal with a searchable knowledge
                                        base and online community space. The data analytics in Customer Service
                                        also enable predictive and proactive customer service.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic2.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        <b>
                                            Dynamics 365 for Financials & Operations
                                        </b>
                                        &nbsp;
                                        makes ordering, selling,
                                        invoicing, and reporting easier starting on day one. It allows
                                        users to create personalized sales quotes and convert them into
                                        professional invoices without leaving Outlook like in any other
                                        ERP software. It also makes it easy to automatically process
                                        payments and reconcile bank accounts - easily identifying past-due
                                        accounts to expedite collections. Financial management gets easier
                                        and better with a complete picture of financial data across
                                        accounting, sales, purchasing, inventory, and customer accounts.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic3.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg  pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic4.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        <b>
                                            Dynamics 365 for Field Service
                                        </b>
                                        &nbsp;
                                        streamlines all aspects of
                                        field service operations, from optimized scheduling to predictive
                                        maintenance. It offers a range of tools for service representatives
                                        and dispatchers, including an automated appointment scheduling tool
                                        that maximizes daily appointments. Managing service agreements,
                                        contracts, and warranties across multiple customers and locations
                                        is made effortless. Additionally, Dynamics 365 for Field Service
                                        improves the productivity of technicians by providing them with native mobile
                                        applications that offer real-time and offline data access.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        Dynamics 365 for Field Service streamlines all aspects of
                                        field service operations, from optimized scheduling to predictive
                                        maintenance. It offers a range of tools for service representatives
                                        and dispatchers, including an automated appointment scheduling tool
                                        that maximizes daily appointments. Managing service agreements,
                                        contracts, and warranties across multiple customers and locations
                                        is made effortless. Additionally, Dynamics 365 for Field Service
                                        improves the productivity of technicians by providing them with native mobile applications
                                        that offer real-time and offline data access.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic4.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        <b>
                                            Dynamics 365 for Project Service
                                        </b>
                                        &nbsp;
                                        Automation streamlines project management
                                        and enhances customer satisfaction by enabling sales and project managers
                                        to collaborate on project estimates using templates. It features unified
                                        resourcing and scheduling tools that allow managers to assign the best
                                        consultants to each project based on their skills, availability, and
                                        interest. This helps organizations meet customer
                                        requirements and drive loyalty through successful project delivery.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic5.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg  pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic6.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        <b>
                                            Dynamics 365 Enterprise
                                        </b>
                                        &nbsp;
                                        edition integrates with Adobe
                                        Marketing Cloud to provide a comprehensive marketing
                                        application for organizations. With a unified customer
                                        profile, marketers can create personalized, cross-channel
                                        campaigns that maximize customer loyalty and lifetime
                                        value. Adobe Marketing Cloud enables content-rich campaigns
                                        that can be executed across multiple channels,
                                        and provides organizations with a way to measure ROI.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pb-5'>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <p>
                                        Dynamics 365 Enterprise edition integrates with Adobe
                                        Marketing Cloud to provide a comprehensive marketing
                                        application for organizations. With a unified customer
                                        profile, marketers can create personalized, cross-channel
                                        campaigns that maximize customer loyalty and lifetime value.
                                        Adobe Marketing Cloud enables content-rich campaigns
                                        that can be executed across multiple channels,
                                        and provides organizations with a way to measure ROI.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/MicrosoftDynamic6.png")} alt='Dynamics365' title='Dynamics365' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MicrosoftDynamics365