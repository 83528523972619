import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Company.css"
import { Helmet, HelmetProvider } from "react-helmet-async";

const Company = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/company" />
                    <meta name="keywords" content="About iCloudsoft, Software Development Company in Pune,innovation, collaboration,  commitment to excellence,leadership,10 years of consonant reviews" />
                    <title> About Us | iCloudsoft Technology | Cloud Solutions Provider in Pune </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            About Us
                        </h2>
                        <h1>
                            iCloudsoft Technology are one of the 31 managed Microsoft
                            Gold Partners in the Pune and renowned as one of the
                            largest Managed Service Providers in Maharashtra.
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-lg-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Message from CEO</h3>
                                    <p>iCloudsoft Technology is a Software Development Company in Pune offering a comprehensive portfolio of products and services. We provide business consultancy and optimal solutions through our cloud based products.</p>
                                    <p>Our value proposition includes reduced total cost of ownership, fast and quality computing, high scalability, reduced security risks and superior performance. We help our clients achieve their goals by transforming the shortcomings into business advantages.</p>
                                    <p>In addition to these goals, iCloudsoft Technology also wants to make sure its customers get the best experience possible when using our products and services. We want to make sure every customer gets exactly what they need from us and nothing more so they can fully enjoy their experience with us and be able to take advantage of all of our amazing product offerings!</p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='MassageFromCEO' title='MassageFromCEO' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-5'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Leadership_Team' title='Leadership_Team' />
                                </div>
                            </div>

                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Leadership Team</h3>
                                    <p>
                                        Welcome to the iCloudsoft Technology Leadership Team. Our leadership team is dedicated to driving our company's success through innovation, collaboration, and a commitment to excellence. Our team is comprised of talented individuals with diverse backgrounds and experiences, all of whom share a passion for our company's mission and vision.
                                    </p>
                                    <p>
                                        Each member of our leadership team is responsible for a specific area of the business, and works collaboratively with other team members to ensure that our company is operating at peak performance. Together, we are focused on achieving our goals and delivering exceptional results to our customers.
                                    </p>
                                    <p>
                                        Our leadership team is dedicated to upholding our company's core values and we strive to lead by example in everything we do. We are committed to creating a positive and supportive work environment for all employees, and believe that strong leadership is essential for driving employee engagement and job satisfaction
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Leadership Team</h3>
                                    <p>
                                        Welcome to the iCloudsoft Technology Leadership Team. Our leadership team is dedicated to driving our company's success through innovation, collaboration, and a commitment to excellence. Our team is comprised of talented individuals with diverse backgrounds and experiences, all of whom share a passion for our company's mission and vision.
                                    </p>
                                    <p>
                                        Each member of our leadership team is responsible for a specific area of the business, and works collaboratively with other team members to ensure that our company is operating at peak performance. Together, we are focused on achieving our goals and delivering exceptional results to our customers.
                                    </p>
                                    <p>
                                        Our leadership team is dedicated to upholding our company's core values and we strive to lead by example in everything we do. We are committed to creating a positive and supportive work environment for all employees, and believe that strong leadership is essential for driving employee engagement and job satisfaction
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Leadership_Team' title='Leadership_Team' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg p-lg-4 mb-5"  >
                    <div className='WhatMakes Dosis'>
                        <h2>
                            What Makes iCloudsoft Technology different?
                        </h2>
                        <p>
                            We prioritize your success, providing personalized support and flexible solutions. <br />
                            Your goals' are our driving force at iCloudsoft Technology
                        </p>
                    </div>
                    <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                        <Carousel responsive={responsive}
                            autoPlay={true}
                            swipeable={true}
                            draggable={true}
                            infinite={true}
                            partialVisible={false}
                            showDots={true}
                        >

                            <div>
                                <div className='ClientSaysSecond'>
                                    <div className='SolutionMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/HomeSecondSe1.png")} alt="RemoteSupport" title='RemoteSupport' />
                                        </div>
                                        <div className='Feature-Content'>
                                            <h2>
                                                Remote Support
                                            </h2>
                                            <p>
                                                Connect via phone, chat, or video, anywhere, anytime.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='ClientSaysSecond'>
                                    <div className='SolutionMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/HomeSecondSe2.png")} alt="AuthorisedPartners" title='AuthorisedPartners' />
                                        </div>
                                        <div className='Feature-Content'>
                                            <h2>
                                                Authorised Partners
                                            </h2>
                                            <p className='' style={{ fontSize: "16.3px" }}>
                                                Certified partnerships with renowned vendors.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='ClientSaysSecond'>
                                    <div className='SolutionMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/HomeSecondSe3.png")} alt="24*7" title='24*7' />
                                        </div>
                                        <div className='Feature-Content'>
                                            <h2>
                                                24*7 Monitoring
                                            </h2>
                                            <p>
                                                Identifying and resolving issues before they impact your business.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='ClientSaysSecond'>
                                    <div className='SolutionMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/HomeSecondSe1.png")} alt="RelationshipManagers" title='RelationshipManagers' />
                                        </div>
                                        <div className='Feature-Content'>
                                            <h2>
                                                Relationship Managers
                                            </h2>
                                            <p>
                                                Your Relationship Manager for all queries, concerns, and opportunities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='ClientSaysSecond'>
                                    <div className='SolutionMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/HomeSecondSe2.png")} alt="SecurityAssurance" title='SecurityAssurance' />
                                        </div>
                                        <div className='Feature-Content'>
                                            <h2>
                                                Security Assurance
                                            </h2>
                                            <p>
                                                Comprehensive security assurance for peace of mind.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Company