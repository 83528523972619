import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const IoT = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/iot" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title> IoT | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            IoT consulting services
                        </h2>
                        <h1>
                            Empowering your business with the power of IoT technology
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>IoT consulting services</h3>
                                    <p>
                                        At iCloudsoft, we provide comprehensive IoT consulting services
                                        to businesses looking to adopt IoT and leverage its benefits.
                                        Our team of IoT experts specializes in designing and implementing
                                        IoT solutions for enterprises. We have extensive experience in
                                        working with cloud solution providers, including AWS, Microsoft Azure,
                                        and Google Cloud, to build end-to-end IoT solutions
                                        that seamlessly integrate with existing business processes.
                                    </p>
                                    <p>
                                        Our IoT consulting services cover every aspect of IoT implementation,
                                        from technology roadmap development and solution architecture design
                                        to implementation and support. We work closely with our clients to
                                        understand their business objectives, evaluate their current infrastructure,
                                        and identify areas where IoT can drive efficiencies,
                                        improve operational performance, and enhance customer experiences.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='IoT' title='IoT' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pb-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Building the future of connected devices with our IoT development service
                            </h2>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Requirements Gathering
                                            </h2>
                                            <p>
                                                We work with our clients to understand their specific
                                                business needs and goals for their IoT solution.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Architecture Design
                                            </h2>
                                            <p>
                                                Our team of experts designs the architecture for the IoT solution,
                                                including hardware, software, and cloud components.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Development
                                            </h2>
                                            <p>
                                                We develop custom hardware and software components for the IoT solution.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Integration
                                            </h2>
                                            <p>
                                                We integrate all the components of the IoT solution to
                                                ensure seamless communication and functionality.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Testing
                                            </h2>
                                            <p>
                                                We conduct rigorous testing to ensure the IoT solution is
                                                reliable, secure, and delivers the expected results.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Deployment
                                            </h2>
                                            <p>
                                                We deploy the IoT solution and provide ongoing support and maintenance
                                                to ensure it continues to perform at optimal levels.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg FeatureMain pb-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                How do we work?
                            </h2>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Discovery and Assessment:
                                            </h2>
                                            <p>
                                                In this phase, we assess the client's business needs and
                                                the IoT use cases that can benefit their business. We conduct
                                                a feasibility study to identify the technical and financial
                                                aspects of implementing IoT solutions. Our team works with the client
                                                to understand their business processes and goals.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Architecture and Design:
                                            </h2>
                                            <p>
                                                Based on the requirements gathered in the discovery phase,
                                                we design the architecture of the IoT system. We identify
                                                the hardware and software components required, the communication
                                                protocols, data storage, and analytics components.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Implementation:
                                            </h2>
                                            <p>
                                                Once the architecture is finalized, we proceed with the
                                                implementation of the IoT system. Our team works on configuring
                                                the hardware components, setting up the software components,
                                                and integrating them into the system. We also
                                                develop the necessary APIs and interfaces
                                                for the IoT system to interact with other systems.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Testing:
                                            </h2>
                                            <p>
                                                We carry out rigorous testing of the IoT system to ensure
                                                that it is functioning as expected. We test the
                                                system for functionality, performance, and security.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Deployment and Support:
                                            </h2>
                                            <p>
                                                After testing, we deploy the IoT system and provide ongoing
                                                support and maintenance to ensure its smooth
                                                operation. We monitor the system's performance,
                                                troubleshoot issues, and perform upgrades when necessary.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default IoT