import React, { useRef, useState } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";

const Acronis = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/acronis" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title>Acronis | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Acronis Backup Cloud - Capped Storage
                        </h2>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Secure Web Gateway </h3>
                                    <p>
                                        Acronis Backup Cloud is the ultimate solution for protecting
                                        your assets with cloud backup. Our hybrid cloud technology
                                        ensures that your systems and devices are safe and secure,
                                        no matter where you are. With highly customizable and
                                        cost-effective plans, we provide backup, disaster recovery,
                                        and secure data access for both SMBs and enterprise clients.
                                        Our solutions cover physical, virtual, and cloud server backup
                                        software, storage management, secure file sharing, and system
                                        deployment. We also offer the ultimate protection for Microsoft
                                        Office 365 email, contacts, calendar, and attachments. Trust Acronis Backup
                                        Cloud for all your backup and data protection needs.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='MassageFromCEO' title='MassageFromCEO' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='MassageFromCEO' title='MassageFromCEO' />
                                </div>
                            </div>

                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Key Features of our data protection solution</h3>
                                    <p>
                                        <ul>
                                            <li>
                                                <b>Comprehensive Data Protection </b> <br />
                                                Acronis Backup Cloud provides
                                                easy and fast data protection for all types of data on-premises,
                                                remote systems, private and public clouds, and mobile devices.
                                            </li>
                                            <li>
                                                <b>Fast Backup </b>
                                                <br />
                                                Acronis optimizes data capture to deliver
                                                backup 26% faster than other solutions.
                                            </li>
                                            <li>
                                                <b>Multi-platform Support </b> <br />
                                                Acronis Backup Cloud can protect 15
                                                platforms from a single console, supports four data
                                                encryption standards, and delivers 15-second recovery.
                                            </li>
                                            <li>
                                                <b>Hybrid Backup </b> <br />
                                                The solution offers both local and
                                                cloud backup for hybrid protection.
                                            </li>
                                            <li>
                                                <b>Office 365 Backup </b> <br />
                                                Acronis Backup Cloud provides data
                                                backup for Office 365 email and mailboxes.
                                            </li>
                                            <li>
                                                <b>Wide Support </b> <br />
                                                The solution supports a variety of operating systems
                                                such as Windows, Linux, Mac, iOS, and Android, and various
                                                hypervisors including VMware vSphere, Hyper-V, and Linux KVM.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Key Features of our data protection solution</h3>
                                    <p>
                                        <ul>
                                            <li>
                                                <b>Comprehensive Data Protection </b> <br />
                                                Acronis Backup Cloud provides
                                                easy and fast data protection for all types of data on-premises,
                                                remote systems, private and public clouds, and mobile devices.
                                            </li>
                                            <li>
                                                <b>Fast Backup </b> <br />
                                                Acronis optimizes data capture to deliver
                                                backup 26% faster than other solutions.
                                            </li>
                                            <li>
                                                <b>Multi-platform Support </b> <br />
                                                Acronis Backup Cloud can protect 15
                                                platforms from a single console, supports four data
                                                encryption standards, and delivers 15-second recovery.
                                            </li>
                                            <li>
                                                <b>Hybrid Backup </b> <br />
                                                The solution offers both local and
                                                cloud backup for hybrid protection.
                                            </li>
                                            <li>
                                                <b>Office 365 Backup </b> <br />
                                                Acronis Backup Cloud provides data
                                                backup for Office 365 email and mailboxes.
                                            </li>
                                            <li>
                                                <b>Wide Support</b> <br />
                                                The solution supports a variety of operating systems
                                                such as Windows, Linux, Mac, iOS, and Android, and various
                                                hypervisors including VMware vSphere, Hyper-V, and Linux KVM.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='MassageFromCEO' title='MassageFromCEO' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Benefits</h3>
                                    <p>
                                        <ul>
                                            <li>
                                                <b>Effortless Protection  </b> br
                                                Acronis Backup Cloud provides an intuitive cloud-based web console
                                                that reduces IT workload. You can access the console from any
                                                browser, on any device, and provision complete protection in just a
                                                few minutes with a few clicks. Acronis Universal
                                                Restore enables quick recovery to varied hardware and bare metal.
                                            </li>
                                            <li>
                                                <b>Broadest Offering for Dynamic Environments  </b> br
                                                Acronis Backup Cloud offers a broad range of solutions
                                                tailored to meet the needs of dynamic environments. Its
                                                virtual solution provides agentless backup for VMware and
                                                Hyper-V and recovers hosts to protect your entire virtual
                                                infrastructure. Its cloud solution protects Microsoft Azure
                                                VMs, Amazon EC2 instances, and Office 365 mailbox data to local
                                                and third-party cloud storage, ensuring business continuity and
                                                simplified recovery. Its physical solution supports all users, including Windows
                                                PCs and Macs, laptops, desktops, and mobile devices.
                                            </li>
                                            <li>
                                                <b>Ultimate Peace of Mind  </b> br
                                                Acronis Backup Cloud encrypts data, in transit and at rest,
                                                protecting against unauthorized access. You can choose to
                                                store your data in tier 4 certified Acronis Cloud Storage
                                                Data Centers, ensuring the highest level of security. The
                                                solution offers extensive monitoring and reporting, enabling
                                                you to receive flexible and customizable alerts
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='MassageFromCEO' title='MassageFromCEO' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg p-4 Dosis">
                    <div className="row p-lg-4 mt-2">
                        <div className=" ">
                            <h3 className="Dosis fw-bold">Flexible Storage Options</h3>
                        </div>

                        <div className="mt-2" style={{ fontSize: "21px" }}>
                            <p>Acronis Backup Cloud offers a range of plans with various storage capacities to suit your needs. Starting from a minimum capacity of 10GB, you can upgrade to a maximum of 1TB of storage for your online backups, synced files, virtual machines, or applications. With a range of options to choose from, you can select the storage capacity that is best for your business, and easily scale up or down as your storage requirements change over time.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="container-fluid" style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg FeatureMain pt-4 pb-5 Dosis">
                        <h3 className='p-3 text-center mb-3 pt-lg-5 pb-lg-5'>
                            Acronis Backup Cloud - Capped Storage
                        </h3>

                        <div className="row g-4 d-flex ">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 10 GB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6" >
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 50 GB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 100 GB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6" >
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 250 GB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 350 GB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6" >
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 500 GB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 750 GB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6" >
                                <div className="p-4" style={{ backgroundColor: "#f5f5f5", boxShadow: "5px 4px 2px 5px #e5e5e5" }}>
                                    <h4 className=" fs-5  mt-2" style={{ color: "#3262b5" }}><b>Acronis Backup Cloud with 1TB File Storage</b></h4>
                                    <h6 className=" mt-3 " style={{ fontSize: "18px" }}>Excessive usage may incur additional charges at higher rates</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Acronis