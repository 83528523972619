import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MicrosoftAzure = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/microsoftazure" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology ,Microsoft, Azure" />
                    <title>  Microsoft Partner | Cloud Solutions Provider in Pune </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Microsoft Azure
                        </h2>
                        <h1>
                            Bulid with iCloudsoft on Azure
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Microsoft Azure</h3>
                                    <p>
                                        Looking for hassle-free migration of your on-premise
                                        application to Microsoft Cloud? Look no further than
                                        iCloudsoft, a trusted silver partner of Microsoft.
                                        Our expert team offers affordable and reliable migration
                                        solutions to clients across India. Find proven and
                                        easy-to-implement solutions to your migration
                                        problems, backed by round-the-clock support services
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Microsoft_Azure' title='Microsoft_Azure' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5">
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Azure products and services
                            </h2>
                            <p>
                                Azure isn't just another cloud platform; it's a game-changer for businesses
                                looking to innovate and stay ahead of the curve. With features
                                like scalability, security, and flexibility, Azure empowers businesses
                                to focus on what they do best while leaving the heavy lifting to the cloud.
                                Plus, with a pay-as-you-go pricing model, you only pay for what you use,
                                making Azure both cost-effective and efficient.
                            </p>
                        </div>
                        <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >


                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/MicrosoftAzur1.png")} alt="Compute_Services" title='Compute_Services' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Compute Services
                                            </h2>
                                            <p>
                                                Azure provides a virtual machine service that
                                                allows users to launch general-purpose
                                                virtual machines as part of its compute services.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/MicrosoftAzur2.png")} alt="Mobile_services" title='Mobile_services' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Mobile services
                                            </h2>
                                            <p>
                                                Mobile engagement in Azure allows you to collect
                                                real-time analytics on users' behavior
                                                and send push notifications to engage with them.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/MicrosoftAzur3.png")} alt="Storage_services" title='Storage_services' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Storage services
                                            </h2>
                                            <p>
                                                Azure's storage services offer the REST and
                                                SDK APIs that enable users to the securely
                                                store and access their data on the cloud.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/MicrosoftAzur4.png")} alt="Data_Management" title='Data_Management' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Data Management
                                            </h2>
                                            <p>
                                                Azure Search the is a cloud-based search
                                                service that enables developers
                                                to the search capabilities to their applications.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MicrosoftAzure