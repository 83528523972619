import React, { useRef, useState } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";

const Veeam = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/veeam" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title> Veeam | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Veeam Backup
                        </h2>
                        <h1>
                            Comprehensive data protection for businesses of all sizes
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Veeam Backup and replication for virtual environments</h3>
                                    <p>
                                        In today’s ever-changing computing landscape, backup
                                        and recovery solutions are vital to any organization's
                                        operations. However, traditional backup solutions may
                                        no longer be effective, especially for firms that heavily
                                        rely on virtualization. Veeam Backup and Replication offers
                                        backup and disaster recovery solutions that are tailored to
                                        meet the unique needs of virtual environments. With Veeam,
                                        organizations can easily protect their virtual environments with efficient,
                                        reliable and flexible backup and recovery solutions.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Veeam_Backup' title='Veeam_Backup' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-5'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Obsolete_backup_solutions' title='Obsolete_backup_solutions' />
                                </div>
                            </div>

                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Obsolete backup solutions</h3>
                                    <p>
                                        Traditional backup solutions such as tape backup have
                                        been used for a long time. However, they may no longer
                                        be effective in today’s fast-changing computing landscape,
                                        particularly if your organization relies heavily on
                                        virtualization. Tape backup restores take a longer time
                                        to complete compared to disk restores or using the backup
                                        as the primary storage. Additionally, finding data from tapes
                                        is a time-consuming and risky process. There is also a risk of
                                        obsolescence of the media when the tape software or drive gets
                                        upgraded. Finally, tape solutions cannot guarantee
                                        the availability that modern businesses require.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Obsolete backup solutions</h3>
                                    <p>
                                        Traditional backup solutions such as tape backup have
                                        been used for a long time. However, they may no longer
                                        be effective in today’s fast-changing computing landscape,
                                        particularly if your organization relies heavily on
                                        virtualization. Tape backup restores take a longer time
                                        to complete compared to disk restores or using the backup
                                        as the primary storage. Additionally, finding data from tapes
                                        is a time-consuming and risky process. There is also a risk of
                                        obsolescence of the media when the tape software or drive gets
                                        upgraded. Finally, tape solutions cannot guarantee
                                        the availability that modern businesses require.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Obsolete_backup_solutions' title='Obsolete_backup_solutions' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Dosis  pt-lg-4 pb-lg-4 ">
                    <div className="row p-4 pt-4 pb-4">
                        <div className="p-3 pb-4">
                            <h3 className="text-lg-center fw-bold ">Enter Veeam for the virtual world</h3>
                        </div>

                        <div className="p-lg-3 " style={{ fontSize: "19px" }}>
                            <p>Veeam Backup & Replication is an industry-leading backup and disaster recovery solution that is specifically designed for the virtual environment. It works by backing up virtual machine images rather than individual files, and then storing those images in a secure, central location. This approach enables Veeam to provide rapid, reliable recovery in the event of a disaster.</p>
                            <p>Veeam Backup & Replication leverages the power of the hypervisor, which is a layer of software that allows multiple virtual machines to run on a single physical machine. The software takes a full backup of each virtual machine and subsequently uses caching and duplication elimination processes to store only new information in the VM images. This approach reduces backup times and storage requirements while ensuring that all data is protected.</p>
                            <p>In the event of a disaster, Veeam can rapidly restore virtual machines using the backed-up images, with a recovery time objective (RTO) and recovery point objective (RPO) of less than 15 minutes for all data and applications. This enables organizations to quickly get back up and running after a disaster, minimizing downtime and ensuring business continuity.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#F5F5F5" }}>
                    <div className="container-lg Dosis FeatureMain">
                        <div className="row p-2 pt-4 pb-4">
                            <div className="p-3 ">
                                <h3 className="text-center fw-bold ">How Veeam works</h3>
                            </div>

                            <div className=" " style={{ fontSize: "21px" }}>
                                <p>Veeam Backup and Replication is designed to work in the virtual environment, specifically with hypervisors such as VMware and Microsoft Hyper-V. Veeam's software runs through the hypervisor, which is a layer of software that allows multiple virtual machines (VMs) to run on a single physical machine.</p>
                                <p>Veeam's first task is to take a full backup of each VM, storing the images in a secure, central location. Then, Veeam uses caching and duplication elimination processes to store only new information in the VM images, minimizing storage space and time needed for backups.</p>
                                <p>In case of a disaster, Veeam's instant VM recovery feature can rapidly restore the VM images and their data. Veeam also offers replication services, which replicate VM images to a secondary location for faster disaster recovery and improved business continuity. Overall, Veeam offers backup and disaster recovery solutions that are tailored for the virtual environment, providing faster recovery times and increased data protection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg Dosis FeatureMain">
                        <div className="row p-lg-2 pt-4 pb-4">
                            <div className="p-3 pt-3 pb-4 ">
                                <h2 className="text-center  fw-bold">Veeam benefits and options</h2>
                            </div>

                            <div className=" ">
                                <ul className='' style={{ listStyle: "none" }}>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Fast, flexible and reliable recovery</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam provides near-instantaneous recovery of virtualized applications, files and data, enabling you to minimize downtime and get back up and running quickly.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Automated backup and replication:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam automates the backup and replication of virtual machines, reducing the risk of data loss and ensuring that your data is always protected.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Advanced monitoring and reporting:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam offers advanced monitoring and reporting capabilities, enabling you to track the performance of your virtual environment and identify issues before they become critical.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Support for multiple platforms:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam supports a wide range of platforms, including VMware vSphere, Microsoft Hyper-V, Nutanix AHV, and more.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Cloud integration:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam integrates with public clouds like AWS, Azure, and Google Cloud, enabling you to back up your data to the cloud and recover it quickly and easily.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Scalability:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam can scale to meet the needs of any organization, from small businesses to large enterprises.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Cost-effective:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam is a cost-effective backup and recovery solution, with flexible licensing options to fit any budget.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold" >Versatile recovery options:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam provides a range of recovery options, from file-level recovery to full VM recovery and everything in between.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">Security and compliance:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam ensures that your data is secure and compliant with industry standards, with built-in encryption and compliance reporting features.</p></li>
                                    <li><h5><i className="fontFamilyHeader fs-5 fw-bold">24/7 support:</i></h5><p className=" " style={{ fontSize: "20px" }}> Veeam provides 24/7 technical support, ensuring that you always have access to the help you need when you need it.</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Veeam