import React from 'react'
import "./Login.css"

const Login = () => {
    return (
        <>

            <div className="container-fluid">
                <div className="container mt-4 pb-5 saira">
                    <div className="row d-flex justify-content-center">
                        <div className="contact-form">
                            <h2 className='text-center mb-4'>
                                Login Form
                            </h2>

                            <form
                                action="https://api.web3forms.com/submit" method="POST"
                                className='contact-inputs'>
                                <input type="hidden" name="access_key" value="bb90b45f-0e1d-44e2-a201-781bf5d7d6d0"></input>
                                <input
                                    type="text"
                                    placeholder='username'
                                    name='username'
                                    required
                                    autoComplete='off'
                                />

                                <input
                                    type="email"
                                    placeholder='Email'
                                    name='Email'
                                    required
                                    autoComplete='off'
                                />

                                <textarea
                                    name="Massage"
                                    cols="30"
                                    rows="4"
                                    placeholder='Enter Your Massage'
                                    required autoComplete='off' >
                                </textarea>

                                <input type="submit" value='send' />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login