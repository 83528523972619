import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Review.css"

const Review = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>


            <div className="container-lg p-lg-4 mb-5"  >
                <div className='OurHostingServices Dosis'>
                    <h2>
                        Our Wide Range of Plans
                    </h2>
                    <p>
                        Choose from our flexible plans to suit your growing needs. Shared, VPS, <br />
                        Dedicated, and Cloud hosting options available.
                    </p>
                </div>
                <div className="row pt-5 pb-4 p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                    <Carousel responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        infinite={true}
                        partialVisible={false}
                        showDots={true}
                    >

                        <div>
                            <div className='ClientSays'>
                                <img className='ClientSaysImge' src={require("../Img/Review1.png")} alt="Review" title='Review' />
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        Anna Kendrick
                                    </h3>
                                    <h6>
                                        Ceo, Hosting
                                    </h6>
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p>
                                        iCloudsoft Technology's Google Workspace integration has revolutionized our workflow.
                                        The seamless integration of Google Drive,
                                        Docs, and Sheets has enhanced teamwork and efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='ClientSays'>
                                <img className='ClientSaysImge' src={require("../Img/Review2.png")} alt="Review" title='Review' />
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        Clavin harris
                                    </h3>
                                    <h6>
                                        Ceo, Hosting
                                    </h6>
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p className='' style={{fontSize:"16px"}}>
                                        Their cloud infrastructure has enabled us to easily manage
                                        and access our data from anywhere.
                                        The team's expertise in migration and setup was invaluable.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='ClientSays'>
                                <img className='ClientSaysImge' src={require("../Img/Review2.png")} alt="Review" title='Review' />
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        Clavin harris
                                    </h3>
                                    <h6>
                                        Ceo, Hosting
                                    </h6>
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p>
                                        iCloudsoft Technology's email solution has simplified our
                                        communication, reducing spam and increasing productivity. The intuitive
                                        interface and robust security features are impressive.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='ClientSays'>
                                <img className='ClientSaysImge' src={require("../Img/Review2.png")} alt="Review" title='Review' />
                                <div className='ClientSays-TestimonialSection'>
                                    <h3>
                                        Clavin harris
                                    </h3>
                                    <h6>
                                        Ceo, Hosting
                                    </h6>
                                    <div>
                                        <ul class="list-unstyled d-flex justify-content-center" style={{ color: "#feda12" }} >
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="fas fa-star fa-sm"></i></li>
                                            <li className='p-1' ><i class="far fa-star fa-sm"></i></li>
                                        </ul>
                                    </div>
                                    <p>
                                        iCloudsoft Technology's IT infrastructure has exceeded our
                                        expectations, delivering scalability, security, and
                                        efficiency. Their expert team and
                                        innovative solutions have transformed our operations.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Carousel>
                </div>
            </div>


        </>
    )
}

export default Review