import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CloudMigration = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/cloudmigration" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title> Cloud Migration | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Cloud Migration Services
                        </h2>
                        <h1>
                            Migrate to Cloud, transform your business
                            seamlessly migrate your business to the cloud .
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>
                                        Migrate to Azure & AWS.
                                    </h3>
                                    <p>
                                        Moving to the cloud should bring numerous benefits
                                        to your business, such as increased security,
                                        scalability, systems integration, accessibility,
                                        and cost-savings. If you're not experiencing these
                                        benefits, something may have gone wrong.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Azure' title='Azure' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                iCloudsoft's Cloud Migration Services
                            </h2>
                            <p>
                                iCloudsoft Cloud Migration Services offer a strategic pathway for businesses
                                to unlock the full potential of the cloud while ensuring a smooth and secure
                                transition. With a focus on innovation, expertise, and client satisfaction,
                                iCloudsoft stands as a reliable partner in the digital transformation journey.
                                Embrace the power of cloud migration with iCloudsoft and pave the way for enhanced efficiency,
                                scalability, and competitiveness in today's dynamic business landscape.
                            </p>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                iCloudsoft can help.
                                            </h2>
                                            <p>
                                                iCloudsoft specializes in quick and easy cloud migration
                                                services for businesses. Our technical expertise and
                                                partnership with leading cloud providers such as AWS,
                                                Azure, and Google Cloud enable us to help you
                                                fully leverage the benefits of a cloud environment.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Migrating to the normal
                                            </h2>
                                            <p>
                                                The Covid-19 pandemic has brought new challenges for
                                                businesses to maintain continuity of services despite
                                                remote working, cost pressures, and faster delivery demands.
                                                With iCloudsoft's comprehensive cloud migration services,
                                                including consulting, implementation, advisory, and management,
                                                we can swiftly and securely deploy the best cloud
                                                model for your needs - public, private, or hybrid.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                iCloudsoft Expertise.
                                            </h2>
                                            <p>
                                                At iCloudsoft, we understand that cloud migration can be a complex
                                                and daunting process. That's why we offer a comprehensive range of
                                                cloud migration services to help you smoothly and securely transition
                                                your applications, data, and systems to the cloud. Our team of experts
                                                has tried and tested strategies for migrating between various cloud providers,
                                                service and deployment models, and can help you select the best options to meet
                                                your specific IT needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                AWS Cloud Migration
                                            </h2>
                                            <p>
                                                Experience a seamless Cloud Migration journey with iCloudsoft's
                                                AWS Cloud Migration Service. As a trusted AWS partner with vast
                                                experience across various industries, we can help businesses of any
                                                size or scale painlessly migrate databases, applications, and services from any
                                                environment to AWS, whether it's on-prem or another cloud.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Azure Cloud Migration
                                            </h2>
                                            <p>
                                                At iCloudsoft, we offer end-to-end cloud migration services
                                                for Microsoft Azure. We begin by conducting IT audits to evaluate
                                                your current infrastructure, and then create a feasibility plan
                                                for your migration. Our team of experts will ensure that your data,
                                                applications, and processes are migrated securely and in compliance
                                                with global regulations.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Cloud Application Support
                                            </h2>
                                            <p>
                                                At iCloudsoft, we understand that uninterrupted business operations
                                                are critical to your success. That's why we offer comprehensive services
                                                to ensure that your business processes run smoothly, without any disruptions.
                                                We have the expertise to handle the complexities involved on both business and
                                                technology levels, fixing issues related to applications, systems, or any incidents
                                                that may interrupt your services.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Disaster Recovery
                                            </h2>
                                            <p>
                                                iCloudsoft's cloud-based disaster recovery services cover applications,
                                                servers, and virtual machines, ensuring business continuity and smooth IT
                                                system operations in case of disasters. Our experts can help you quickly recover
                                                and restore your applications and software ecosystems with minimal downtime. We use
                                                proven database mirroring strategies to ensure consistent recovery.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                iCloudsoft Cloud Migration Consultancy:
                                            </h2>
                                            <p>
                                                At iCloudsoft, we understand that digital transformation can be a
                                                complex and daunting process. That's why our team of experts is here
                                                to guide you every step of the way. We start by helping you define the
                                                best strategy to achieve your objectives, and then work with you to build a
                                                roadmap that outlines the ideal workflow model.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                cost-effective transition to cloud computing?
                                            </h2>
                                            <p>
                                                iCloudsoft is a leading provider of AWS Cloud Migration Services,
                                                helping businesses to migrate their applications, data, and services
                                                from any environment to AWS with ease. With our expertise and experience
                                                as an AWS partner, we can help you achieve faster business results and an
                                                efficient cloud migration experience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid " style={{ backgroundColor: "#fff" }}>
                    <div className="container-lg Dosis text-center FeatureMain">
                        <div className='fs-2 p-2 mt-3 mb-3 pb-lg-4'>
                            <h2 className='' style={{ fontWeight: "600" }}>Cloud Migration Services</h2>
                        </div>
                        <div className="row g-lg-4 g-2 row-cols-lg-3 row-cols-sm-2 row-cols-1 mb-4">
                            <div className="col">
                                <div className='p-3'>
                                    <img className='w-100' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/Easier-Scaling.png")} alt='Easier-Scaling' title='Easier-Scaling' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>Easier Scaling</h4>
                                </div>
                            </div>

                            <div className="col">
                                <div className='p-3'>
                                    <img className='w-100' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/Increase-in-accessibility.png")} alt='Increase-in-accessibility' title='Increase-in-accessibility' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>Increase in accessibility</h4>
                                </div>
                            </div>

                            <div className="col">
                                <div className='p-3'>
                                    <img className='w-100 ' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/Better-documentation-access-controls.png")} alt='Better-documentation-access-controls' title='Better-documentation-access-controls' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>Better documentation access controls</h4>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='p-3'>
                                    <img className='w-100' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/Automatic_Software_Updates.png")} alt="Automatic_Software_Updates" title='Automatic_Software_Updates' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>Automatic updates to the software</h4>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='p-3'>
                                    <img className='w-100' style={{ height: "250px", backgroundColor: "white", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/Reduction-complexity.png")} alt="Reduction-complexity" title='Reduction-complexity' />
                                </div>

                                <div className='f text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>Reduction in infrastructure complexity</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CloudMigration