import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CloudDisaster = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/cloudDisasterRecovery" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title> Cloud Disaster | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Cloud Disaster Recovery
                        </h2>
                        <h1>
                            Don't let disasters stop your business - use Cloud Disaster Recovery
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Cloud Disaster Recovery services</h3>
                                    <p>
                                        iCloudsoft provides fast, reliable, and comprehensive disaster
                                        recovery solutions for businesses of all sizes. In today's digital
                                        age, business continuity is critical for organizations to ensure
                                        uninterrupted operations and customer satisfaction. Any cloud service
                                        downtime can result in loss of revenue, productivity, and damage
                                        to brand reputation. Therefore, it is crucial to protect your valuable business data
                                        from natural disasters, calamities, and potential threats.
                                    </p>
                                    <p>
                                        At iCloudsoft, we offer complete cloud disaster recovery services
                                        for your IT environment on a secure and robust platform. Our
                                        cloud-based disaster recovery service is a cost-effective alternative
                                        to traditional recovery solutions. We ensure that your business continues to run smoothly while we
                                        perform the recovery mechanism on your cloud infrastructure.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='CloudDisasterRecoveryServices' title='CloudDisasterRecoveryServices' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Benefits of our Cloud Disaster Recovery
                            </h2>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                TCO reduction
                                            </h2>
                                            <p>
                                                Your disaster recovery solutions help reduce the total
                                                cost of ownership (TCO) for customers by synchronizing
                                                business data with the primary data center.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                IT infrastructure readiness
                                            </h2>
                                            <p>
                                                You perform a complete audit of customers' cloud
                                                infrastructure and implement readiness tests
                                                before going live with disaster recovery mechanisms.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Reduction of infrastructure costs
                                            </h2>
                                            <p>
                                                Your curated and proven solutions help customers
                                                reduce infrastructure costs by paying only for the
                                                computing resources that support their applications.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Non-disruptive cloud solution
                                            </h2>
                                            <p>
                                                Your disaster recovery solutions operate in the background
                                                without impacting ongoing business processes, and your continuous data protection
                                                engine works in memory with no impact on cloud performance.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Process automation
                                            </h2>
                                            <p>
                                                Your solutions support all cloud infrastructure across
                                                various operating systems, databases, and workloads, and automate the
                                                process of replication and recovery of such elements.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Faster recovery
                                            </h2>
                                            <p>
                                                Your team performs an in-depth assessment of customers'
                                                cloud, making the recovery process
                                                faster as they are aware of any potential issues.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid " style={{ backgroundColor: "#fff" }}>
                    <div className="container-lg Dosis text-center FeatureMain">
                        <div className='fs-2 p-2 mt-3 mb-3 pb-lg-4'>
                            <h2 className='' style={{ fontWeight: "600" }}>Backing up from a Cloud data center</h2>
                        </div>
                        <div className="row g-lg-4 g-2 row-cols-lg-3 row-cols-sm-2 row-cols-1 mb-4">
                            <div className="col">
                                <div className='p-3'>
                                    <img className='w-100' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/CloudDisater1.png")} alt='Easier-Scaling' title='Easier-Scaling' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>From a private cloud environment</h4>
                                </div>
                            </div>

                            <div className="col">
                                <div className='p-3'>
                                    <img className='w-100' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/CloudDisater2.png")} alt='Increase-in-accessibility' title='Increase-in-accessibility' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>Public and Hybrid cloud environment</h4>
                                </div>
                            </div>

                            <div className="col">
                                <div className='p-3'>
                                    <img className='w-100 ' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/CloudDisater3.png")} alt='Better-documentation-access-controls' title='Better-documentation-access-controls' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>The identical cloud service used to keep statistics</h4>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='p-3'>
                                    <img className='w-100' style={{ height: "250px", backgroundColor: "#1e7898", borderRadius: '20px 5px 20px 5px' }} src={require("../Img/CloudDisater4.png")} alt="Automatic_Software_Updates" title='Automatic_Software_Updates' />
                                </div>

                                <div className='fs-5 text-center p-2'>
                                    <h4 style={{ color: "#284167", fontWeight: "600", fontSize: "20px" }}>To restore to its original cloud environment</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CloudDisaster