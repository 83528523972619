import React, { useRef, useState } from 'react'
import "./Contact.css"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Contact = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.subject = ""; errors.massage = "";

            var url = "https://wa.me/9527468898?text="
                + " Name : " + name + " Email : " + email + " Subject : " + subject + " Content : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setSubject("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }
        if (!subject) {
            errors.subject = "Subject Is Requried";
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;

    }

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/contact" />
                    <meta name="keywords" content="We are here to help, HEAD OFFICE, PUNE" />
                    <title>Contact Us | iCloudsoft Technology | Cloud Solutions Provider in Pune </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Contact Us
                        </h2>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#dfdbce" }}>
                    <div className="container">
                        <div className="row">
                            <div className='TopHeader'>
                                <p className=''>
                                    <a className="text-decoration-none" href='/'>
                                        <span className='TopHeaderFirst'>
                                            Home
                                        </span>
                                    </a>
                                    <span className='TopHeaderIcon'>
                                        <i class="fa-sharp fa-solid fa-angle-right"></i>
                                    </span>
                                    <span className='TopHeaderSecond'>
                                        Contact Us
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg pb-5 text-center ContactMain">
                        <div className='ContactMain-Sub Dosis'>
                            <h2>
                                Contact Us
                            </h2>
                            <p>
                                For inquiries, feedback, or to request more information about our products and services, <br />
                                please drop a query or message to our dedicated team.
                            </p>
                        </div>
                        <div className="row mt-lg-3 g-4">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='' src={require("../Img/Contact1.png")} alt="Office" title='Office' />
                                </div>
                                <div className='Contact-detail'>
                                    <h2>
                                        Our Office
                                    </h2>
                                    <p>
                                        Office No 701, 7th Floor, Lotus Building, <br /> Opp Joshi Railway Museum Kothrud , <br /> Pune 411038
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='' src={require("../Img/Contact2.png")} alt="Call" title='Call' />
                                </div>
                                <div className='Contact-detail'>
                                    <h2>
                                        Call Us
                                    </h2>
                                    <p>
                                        <a className='text-decoration-none' href="tel:9527468898">
                                            +91 9527468898
                                        </a>
                                        &nbsp;,&nbsp;
                                        <a className='text-decoration-none' href="tel:8766779267">
                                            8766779267
                                        </a>
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='' src={require("../Img/Contact3.png")} alt="Email" title='Email' />
                                </div>
                                <div className='Contact-detail'>
                                    <h2>
                                        Email Us
                                    </h2>
                                    <p>
                                        <a className='text-decoration-none' href="mailto:sales@icloudsoft.co.in" >
                                        sales@icloudsoft.co.in
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid p-0 ">
                <div className='' style={{ lineHeight: "0px" }}>
                    <iframe className='w-100 m-0 ContactMap' title="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.6079333821867!2d73.81751827465172!3d18.501410169760305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfeaf279b111%3A0x71a01261ef40607a!2sLotus%20Residency!5e0!3m2!1sen!2sin!4v1728458454322!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>

            <section>
                <div className="container-lg ContactMainSection">
                    <div className="row g-4 d-flex justify-content-center FormSection">
                        <div className='FormSectionTitle '>
                            <h2>
                                Leave Us a Message
                            </h2>
                        </div>
                        <div className="col-xl-8 col-md-10 col-sm-12">
                            <form ref={form} onSubmit={loginHandle} className="row g-2">
                                <div class="col-lg-6 col-12 ">
                                    <input type="text" placeholder='Name' className="form-control CommonInput" id="inputName" name="name"
                                        onChange={e => setName(e.target.value)} value={name}
                                    />
                                    {errors.name && <div className='CommonErrors' >{errors.name}</div>}
                                </div>

                                <div className='col-lg-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CommonInput" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div class="col-lg-12 ">
                                    <input type="text" placeholder='Subject' className="form-control CommonInput" id="inputName" name="name"
                                        onChange={e => setSubject(e.target.value)} value={subject}
                                    />
                                    {errors.subject && <div className='CommonErrors'>{errors.subject}</div>}
                                </div>

                                <div className="col-lg-12">
                                    <textarea type="text" placeholder='Write here..' className="form-control CommonInputBig" id="inputText3" name="message"
                                        onChange={e => setMassage(e.target.value)} value={massage}
                                    />
                                    {errors.massage && <div className='CommonErrors'>{errors.massage}</div>}
                                </div>

                                <div className="col-lg-12 mt-lg-2">
                                    <div className='d-flex justify-content-center mb-3 ContactSubmit'>
                                        <button type="submit" className=""  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact