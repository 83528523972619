import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CloudMange.css"
import { Helmet, HelmetProvider } from "react-helmet-async";

const CloudMange = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/cloudmange" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title> Cloud Manage | Cloud Solutions Provider in Pune </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Cloud Managed Services
                        </h2>
                        <h1>
                            Simplify your business operations with Cloud Managed Services
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Cloud Managed services for higher ROI</h3>
                                    <p>
                                        Maximize your cloud investment with iCloudsoft's
                                        Cloud Managed Services. Monitoring and managing a
                                        cloud environment can be complex and costly, but
                                        our team of experts can efficiently manage your
                                        resources and align them with your business
                                        objectives. By outsourcing cloud management to
                                        us, you can focus on your core business needs
                                        without the added burden of managing a complex
                                        cloud environment. With our experience and
                                        understanding, we can deliver higher ROI and ensure your
                                        cloud environment is running at peak efficiency.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='CloudManagedServices' title='CloudManagedServices' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Get rid of operational risks, security issues, and runaway costs with iCloudsoft's cloud managed services
                            </h2>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <h2 className='pb-lg-3'>
                                Operational risks:
                            </h2>
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Data loss or corruption:
                                            </h2>
                                            <p>
                                                During migration,and data can be lost or
                                                corrupted, which can result
                                                in data breaches or system failures.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Downtime:
                                            </h2>
                                            <p>
                                                The migration process can lead to system
                                                downtime, which can result in lost productivity,
                                                revenue, and customer dissatisfaction.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Incompatibility issues:
                                            </h2>
                                            <p>
                                                Applications that are not compatible with the
                                                cloud environment can cause operational issues,
                                                such as poor performance or system crashes.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Security vulnerabilities:
                                            </h2>
                                            <p>
                                                During migration, security controls may be weakened or overlooked,
                                                leaving systems vulnerable to cyber threats.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Compliance risks:
                                            </h2>
                                            <p>
                                                Migrating to the cloud may introduce compliance and regulatory
                                                risks, such as data protection and privacy requirements.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Supply chain disruptions:
                                            </h2>
                                            <p>
                                                Supply chain disruptions can impact business operations and lead
                                                to delays, cancellations, and revenue loss.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>

                        <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <h2 className='pb-lg-3'>
                                Security Risks:
                            </h2>
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Data loss or corruption:
                                            </h2>
                                            <p>
                                                During migration,and data can be lost or
                                                corrupted, which can result
                                                in data breaches or system failures.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Downtime:
                                            </h2>
                                            <p>
                                                The migration process can lead to system
                                                downtime, which can result in lost productivity,
                                                revenue, and customer dissatisfaction.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Incompatibility issues:
                                            </h2>
                                            <p>
                                                Applications that are not compatible with the
                                                cloud environment can cause operational issues,
                                                such as poor performance or system crashes.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Security vulnerabilities:
                                            </h2>
                                            <p>
                                                During migration, security controls may be weakened or overlooked,
                                                leaving systems vulnerable to cyber threats.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Compliance risks:
                                            </h2>
                                            <p>
                                                Migrating to the cloud may introduce compliance and regulatory
                                                risks, such as data protection and privacy requirements.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Supply chain disruptions:
                                            </h2>
                                            <p>
                                                Supply chain disruptions can impact business operations and lead
                                                to delays, cancellations, and revenue loss.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg FeatureMain">
                    <div className='CloudMange Dosis'>
                        <h2>
                            iCloudsoft’s Managed Services catering to all your cloud needs
                        </h2>
                    </div>
                    <div className='row p-3 mb-3 text-center g-lg-4 g-3 Dosis' >
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3 text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Deployment</b>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3    text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Cloud Migration Service</b>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3    text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Security</b>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3    text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Network and storage</b>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3    text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Cloud Monitoring & Reporting</b>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3    text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Backup and Recovery</b>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3    text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Infrastructure Set Up</b>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 " >
                            <div className='p-3    text-white d-flex align-items-center justify-content-center' style={{ height: "100px", backgroundColor: "#002e4a", borderRadius: '50px 0px 50px 0px' }} >
                                <b className='' style={{ fontSize: "16px" }}>Business Continuity</b>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#f3f2f0" }}>
                    <div className='container-lg FeatureMain'>
                        <div className='row p-3'>
                            <div className='CloudBottom'>
                                <p>
                                    With iCloudsoft's AWS Cloud Managed Services, you can take advantage
                                    of our expertise in managing a variety of cloud environments to automate
                                    practices, streamline deployments, and optimize cloud environments and costs.
                                    We ensure that your cloud is properly deployed, set up and managed, making a
                                    significant difference to your business operations and bottom line.
                                </p>
                                <li>Enhanced value through automation and self-serve: By automating processes and enabling self-service capabilities, you can increase efficiency and reduce the burden on your IT staff. This can lead to cost savings and faster delivery of services.</li>
                                <li>Find the pricing, delivery, and management model that works for you: With various pricing and delivery models available, you can choose the one that best fits your budget and needs. Additionally, with cloud managed services, you can rely on experts to manage and optimize your cloud environment, freeing up resources for other critical business tasks.</li>
                                <li>Meet global regulatory, security, and compliance standards: Cloud managed services providers, like iCloudsoft, have the knowledge and expertise to ensure your cloud environment meets global regulatory, security, and compliance standards. This can help mitigate risks and protect your business from potential breaches or other security threats.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CloudMange