import React from 'react'
import "./HomeSilder.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HomeSilder = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <>

            <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/icon-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                <div className="container-lg pt-4"  >
                    <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">

                        <Carousel responsive={responsive}
                            autoPlay={true}
                            swipeable={true}
                            draggable={true}
                            // showDots={true}
                            infinite={true}
                            partialVisible={false}
                        >

                            <div>
                                <div >
                                    <img
                                        className="ImgSize img-fluid text-center"
                                        src={require("../Img/support-desk.webp")}
                                        alt="support-desk" title='support-desk'
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/Microsoft-Partner.png")}
                                        alt="Microsoft-Partner" title='Microsoft-Partner'
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/Remote-working.webp")}
                                        alt="Remote-working" title='Remote-working'
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/Client-feedback.png")}
                                        alt="Client-feedback" title='Client-feedback'
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid mx-auto d-block"
                                        src={require("../Img/24-7-upport-and-service.webp")}
                                        alt="24-7-upport-and-service" title='24-7-upport-and-service'
                                    />
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeSilder