import React from 'react'
import "./Navbar.css"
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <>

            <section className='Top-Header'>
                <section>
                    <div className="container-fluid d-md-none d-none d-lg-block " style={{ backgroundColor: "#fff", boxShadow: "0 6px 12px rgba(0, 0, 0, .175)" }} >
                        <div className="container-lg laila-bold pt-3 laila-semibold" >
                            <div className="row g-2 ps-xl-2">
                                <ul class=" d-flex justify-content-between align-items-center ms-xl-4 pb-0" style={{ listStyle: "none" }} >
                                    <li className="" style={{ fontSize: "13.5px", lineHeight: "20px", fontWeight: "400", color: "#222", letterSpacing: "1px" }} >
                                        <span>
                                            <a className='text-decoration-none' style={{ color: "#222" }} href="tel:9527468898">
                                                <i class="fa-solid fa-phone me-2"></i>
                                                +91 9527468898
                                            </a>
                                        </span>

                                        <span className='ms-5'>
                                            <a className='text-decoration-none' style={{ color: "#222" }} href="mailto:sales@icloudsoft.co.in" >
                                                <i class="fa-solid fa-envelope me-2"></i>
                                                sales@icloudsoft.co.in
                                            </a>
                                        </span>
                                    </li>

                                    <li className="" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#222", letterSpacing: "1px" }} >
                                        <span className='SocialIcon  text-center'>
                                            <NavLink to="https://www.facebook.com/afk.kadam/" target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                            <NavLink to="https://twitter.com/icloudsoftTech " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                            <NavLink to="https://www.instagram.com/icloud_soft/ " target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                            <NavLink to="https://in.linkedin.com/in/anil-kadam-18b113147 " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                        </span>

                                        <i class="fa-solid fa-lock me-lg-2"></i>
                                        <span className='' style={{ cursor: "pointer" }}>
                                            <a href="/login" className='text-decoration-none' style={{ color: "#222" }}>
                                                <span>
                                                    Login
                                                </span>
                                            </a>
                                            &nbsp; /  
                                            <a href="/register" className='text-decoration-none'>
                                                <span className='ms-1' style={{ color: "#222" }}>
                                                    Register
                                                </span>
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid d-none " style={{ backgroundColor: "#1a1a1a" }} >
                    </div>
                </section>
            </section>

            <section>
                <div className="container-fluid p-0">
                    <nav className='navbar navbar-expand-lg p-0  d-md-none d-none d-lg-block' style={{ backgroundColor: "#222" }} >
                        <div className="container-fluid p-0" style={{ backgroundColor: "#1D87D1" }}   >
                            <div className='LogoSection'>
                                <a href="/"><img src={require("../Img/NewLogo.png")} alt="logo" title='logo' /></a>
                            </div>
                            <button className="navbar-toggler " type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars"></i>
                            </button>

                            <div class="collapse navbar-collapse " id="navbarNavDropdown">
                                <ul class="navbar-nav w-100 d-flex justify-content-center "  >
                                    <li className="nav-items MainPaddingNav" >
                                        <a className="nav-link  " aria-current="page" href="/">Home</a>
                                    </li>

                                    <li className="nav-items MainPaddingNav" >
                                        <a className="nav-link  " aria-current="page" href="/company">Company</a>
                                    </li>

                                    <li className="nav-items #submenu MainPaddingNav" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Solutions <span className='ms-1'>
                                            <i class="fa-solid fa-angle-down"></i>
                                        </span> </div>
                                        <ul className="dropdown-menu w-100 collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                            <li className='nav-items   text-white'  >
                                                <div className='container '  >
                                                    <div className="row d-flex justify-content-end FontFamilyMontSerret">
                                                        <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff", width: "100%" }}>
                                                            <div className="row row-cols-lg-5" style={{ height: "340px", overflow: "auto" }} >
                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1 ' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > GCP </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none  ' href="/googlecloudplatform" >
                                                                        <div className='dropdown-item text-dark '>Google Cloud Platform</div>
                                                                        <div className="border" ></div>
                                                                    </a>

                                                                    <a className='text-decoration-none ' href="/googleworkspace" >
                                                                        <div className='dropdown-item text-dark '>Google Workspace</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > AWS </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/amazonwebservices" >
                                                                        <div className='dropdown-item text-dark '>Amazon Web Services</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1 ' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > Microsoft </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/microsoft365" >
                                                                        <div className='dropdown-item text-dark '>Microsoft 365</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/microsoftdynamics365" >
                                                                        <div className='dropdown-item text-dark '>Microsoft Dynamics 365</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/microsoftazure" >
                                                                        <div className='dropdown-item text-dark '>Microsoft Azure</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > Hybrid Cloud </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/cloudmange" >
                                                                        <div className='dropdown-item text-dark '>Cloud Manage</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/cloudmigration" >
                                                                        <div className='dropdown-item text-dark '>Cloud Migration</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/privatecloud" >
                                                                        <div className='dropdown-item text-dark '>Private Cloud</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/devops" >
                                                                        <div className='dropdown-item text-dark '>DevOps</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/iot" >
                                                                        <div className='dropdown-item text-dark '>IOT</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/clouddisaster" >
                                                                        <div className='dropdown-item text-dark '>Cloud Disaster</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }} > Backup </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/acronis">
                                                                        <div className='dropdown-item text-dark '>Acronis</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/veeam" >
                                                                        <div className='dropdown-item text-dark '>Veeam</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-items MainPaddingNav'>
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>

                                    <li className='nav-items MainPaddingNav'>
                                        <a className="nav-link text-decoration-none" href="tel:+91 95274688989"><b className='' style={{ fontWeight: "400" }}> +91 9527468898 </b></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <nav className='navbar navbar-expand-lg p-0  d-lg-none d-md-block' style={{ backgroundColor: "#222" }} >
                        <div className="container-fluid p-0" style={{ backgroundColor: "#fff", boxShadow: "0 6px 12px rgba(0, 0, 0, .175)" }}   >
                            <div className=''>
                                <a href="/" className='LogoSection'>
                                    <img src={require("../Img/ResponsiveLogoNew.png")} alt="logo" title='logo' />
                                </a>
                            </div>
                            <button className="navbar-toggler me-4 bg-dark" style={{ borderRadius: "0px" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars text-white"></i>
                            </button>

                            <div class="collapse navbar-collapse FontFamilyMontSerret " id="navbarNavDropdown">
                                <ul class="navbar-nav w-100 d-flex justify-content-center bg-dark"  >
                                    <li className="nav-items MainPaddingNav" >
                                        <a className="nav-link  " aria-current="page" href="/">Home</a>
                                    </li>

                                    <li className="nav-items MainPaddingNav" >
                                        <a className="nav-link  " aria-current="page" href="/company">Company</a>
                                    </li>

                                    <li className="nav-items #submenu MainPaddingNav" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  text-white mt-2 mb-2" style={{ fontSize: "15px", marginLeft: "10px" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Solutions
                                            <span className='ms-1'>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </div>
                                        <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                            <li className='nav-items   text-white'  >
                                                <div className='container '  >
                                                    <div className="row d-flex justify-content-end FontFamilyMontSerret">
                                                        <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff", width: "100%" }}>
                                                            <div className="row row-cols-lg-5" style={{ height: "340px", overflow: "auto" }} >
                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1 ' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > GCP </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none  ' href="/googlecloudplatform" >
                                                                        <div className='dropdown-item text-dark '>Google Cloud Platform</div>
                                                                        <div className="border" ></div>
                                                                    </a>

                                                                    <a className='text-decoration-none ' href="/googleworkspace" >
                                                                        <div className='dropdown-item text-dark '>Google Workspace</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > AWS </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/amazonwebservices" >
                                                                        <div className='dropdown-item text-dark '>Amazon Web Services</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1 ' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > Microsoft </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/microsoft365" >
                                                                        <div className='dropdown-item text-dark '>Microsoft 365</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/microsoftdynamics365" >
                                                                        <div className='dropdown-item text-dark '>Microsoft Dynamics 365</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/microsoftazure" >
                                                                        <div className='dropdown-item text-dark '>Microsoft Azure</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}  > Hybrid Cloud </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/cloudmange" >
                                                                        <div className='dropdown-item text-dark '>Cloud Manage</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/cloudmigration" >
                                                                        <div className='dropdown-item text-dark '>Cloud Migration</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/privatecloud" >
                                                                        <div className='dropdown-item text-dark '>Private Cloud</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/devops" >
                                                                        <div className='dropdown-item text-dark '>DevOps</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/iot" >
                                                                        <div className='dropdown-item text-dark '>IOT</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/clouddisaster" >
                                                                        <div className='dropdown-item text-dark '>Cloud Disaster</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>

                                                                <div className="" style={{ fontSize: "13px" }}>
                                                                    <div className='dropdown-item mt-3 mb-1' style={{ color: "#000", fontSize: "16px", fontWeight: "600" }} > Backup </div>
                                                                    <div className="border" ></div>

                                                                    <a className='text-decoration-none ' href="/acronis">
                                                                        <div className='dropdown-item text-dark '>Acronis</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                    <a className='text-decoration-none ' href="/veeam" >
                                                                        <div className='dropdown-item text-dark '>Veeam</div>
                                                                        <div className="border" ></div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-items MainPaddingNav'>
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>

                                    <li className='nav-items MainPaddingNav'>
                                        <a className="nav-link text-decoration-none" href="tel:+91 95274688989"><b className='' style={{ fontWeight: "400" }}> +91 9527468898 </b></a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>


        </>
    )
}

export default Navbar