import React, { useRef, useState } from 'react'
import GcpTernaryOpe from './GcpTernaryOpe';
import "./GoogleCloudPlatform.css"
import { Helmet, HelmetProvider } from "react-helmet-async";

const GoogleCloudPlatform = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/googlecloudplatform" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology, Google Cloud Platform" />
                    <title> Google Cloud Partner |  Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Your Google Cloud Platform partner India
                        </h2>
                        <h1>
                            Most trusted Google Cloud partner in India
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-lg-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3> Google Cloud Platform</h3>
                                    <p>
                                        At iCloudsoft, we empower businesses to run seamlessly on the Google Cloud Platform. As a Google Cloud Platform partner, we offer unparalleled expertise in cloud migration, architecture, deployment, and management, enabling businesses to leverage the full potential of cloud computing. Our team of cloud professionals is committed to delivering tailored solutions that are optimized for your business needs, ensuring your data is secure and compliant. Trust iCloudsoft to help your business achieve agility and efficiency with our industry-leading cloud solutions.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='GoogleCloudPlatform' title='GoogleCloudPlatform' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-5'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='GoogleCloudService' title='GoogleCloudService' />
                                </div>
                            </div>

                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Google Cloud Services</h3>
                                    <p>
                                        Google Cloud is a powerful public cloud platform that offers a diverse range of solutions for various industry verticals, providing services on a pay-as-you-go basis through its various components. With a global presence across 25 regions, 77 zones, and 200+ countries and territories, Google Cloud boasts the fastest network for unparalleled performance and reliability. Whether you're a small business or an enterprise-level organization, Google Cloud offers scalable solutions that can be customized to your unique needs, ensuring that you get the most value for your investment. Trust in the power of Google Cloud to help you achieve your business goals and take your organization to new heights.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Google Cloud Services</h3>
                                    <p>
                                        Google Cloud is a powerful public cloud platform that offers a diverse range of solutions for various industry verticals, providing services on a pay-as-you-go basis through its various components. With a global presence across 25 regions, 77 zones, and 200+ countries and territories, Google Cloud boasts the fastest network for unparalleled performance and reliability. Whether you're a small business or an enterprise-level organization, Google Cloud offers scalable solutions that can be customized to your unique needs, ensuring that you get the most value for your investment. Trust in the power of Google Cloud to help you achieve your business goals and take your organization to new heights.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='GoogleCloudService' title='GoogleCloudService' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <GcpTernaryOpe />

            <section>
                <div className="container-fluid">
                    <div className="container-lg FeatureMain">
                        <div className='SolutionTop'>
                            <h2>
                                What are some of the things that can be done with the <br />
                                Google Cloud Platform?
                            </h2>
                        </div>
                        <div className="row mt-3 pb-3 g-4">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 ">
                                <div className='GooglePlatformMain'>
                                    <div className=''>
                                        <img className='' src={require("../Img/HomeSecondSe1.png")} alt="GCP" title='GCP' />
                                    </div>
                                    <div className='Gcp-Content'>
                                        <p>
                                            Google Cloud Platform (GCP) provides a hassle-free
                                            IT environment creation that comes with complete security,
                                            data reliability, and smooth accessibility. With GCP, businesses
                                            can easily deploy any business or mission-critical application without
                                            worrying about infrastructure management.
                                            GCP offers a cost-effective solution .
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className='GooglePlatformMain'>
                                    <div className=''>
                                        <img className='' src={require("../Img/HomeSecondSe2.png")} alt="GCP" title='GCP' />
                                    </div>
                                    <div className='Gcp-Content'>
                                        <p>
                                            Google's user-friendly cloud architecture simplifies the deployment
                                            and management of cloud resources for heavy workloads like SAP on
                                            Cloud and IoT applications. It also allows for the creation of hybrid
                                            and multi-cloud environments. The platform offers flexibility and scalability,
                                            while Google's infrastructure is highly secure and reliable.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className='GooglePlatformMain'>
                                    <div className=''>
                                        <img className='' src={require("../Img/HomeSecondSe3.png")} alt="GCP" title='GCP' />
                                    </div>
                                    <div className='Gcp-Content'>
                                        <p>
                                            Google Cloud offers unique features such as live VM migration,
                                            SQL Server implementation, and custom machine deployment on
                                            Compute Engine and App Engine. These features enable businesses
                                            to handle various workloads, including web hosting, enterprise
                                            applications on cloud, and Kubernetes solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default GoogleCloudPlatform