import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const DevOps = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/devops" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title>DevOps | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            DevOps As A Service
                        </h2>
                        <h1>
                            Continuous delivery, continuous improvement,continuous success - that's DevOps
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>iCloudsoft DevOps consulting</h3>
                                    <p>
                                        iCloud technology has transformed IT infrastructure and
                                        software management, making DevOps essential in modern
                                        software development. Moving DevOps to the cloud enables
                                        faster collaboration and delivery of applications. DevOps
                                        as a Service is the next step for organizations looking to
                                        fully leverage the cloud and streamline their processes.
                                        At iCloudsoft, we offer expert DevOps consulting
                                        to help organizations transition to
                                        DevOps as a Service and optimize their IT environment.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='DevOps' title='DevOps' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                The key benefits of teleglobal's DevOps As A Service
                            </h2>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Faster time-to-market:
                                            </h2>
                                            <p>
                                                By automating manual processes and increasing collaboration
                                                among teams, DevOps helps speed up software development and delivery, allowing
                                                businesses to bring their products to market faster.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Improved the quality:
                                            </h2>
                                            <p>
                                                By continuously testing and monitoring applications throughout
                                                the development process, DevOps helps identify and address
                                                issues early on, improving overall software quality.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Increased efficiency:
                                            </h2>
                                            <p>
                                                By streamlining workflows and automating repetitive tasks,
                                                DevOps helps reduce the time and effort required to deliver software, freeing
                                                up resources for other business-critical activities.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Enhanced collaboration:
                                            </h2>
                                            <p>
                                                By breaking down silos between development and operations
                                                teams, DevOps helps increase communication and collaboration, leading to better
                                                outcomes and higher satisfaction for all stakeholders.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5 '>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>
                                        Leverage iCloudsoft Services for faster delivery and
                                        cost reduction with the following features.
                                    </h3>
                                    <p>
                                        <ul>
                                            <li>Round-the-clock monitoring</li>
                                            <li>Central log</li>
                                            <li>Automated provisioning environment</li>
                                            <li>Data, infrastructure, and service-virtualization</li>
                                            <li>Automated configuration (network, server, storage, and software)</li>
                                            <li>Complete infrastructure management</li>
                                            <li>Automation and optimization of delivery</li>
                                            <li>Security of infrastructure—including code quality control, testing, storage, and more</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/DevOps1.png")} alt='DevOps' title='DevOps1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5 p-lg-4'>
                        <div className='row p-lg-3 d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/DevOps2.png")} alt='DevOps' title='DevOps1' />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>
                                        iCloudsoft DevOps Consulting offers a step-by-step
                                        roadmap for adopting DevOps, including
                                    </h3>
                                    <p>
                                        <ul>
                                            <li>Understanding your requirements and analyzing your business goals</li>
                                            <li>Conducting a full audit of your IT environment and submit an assessment</li>
                                            <li>Assessing the feasibility of implementing DevOps at the project level</li>
                                            <li>Suggest best-fit DevOps implementation models</li>
                                            <li>Recommend ideal DevOps tools and guide integration of toolchain</li>
                                            <li>Estimate costs</li>
                                            <li>Train your in-house DevOps people & set out KPIs for specific projects</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pb-5 p-lg-4'>
                        <div className='row p-lg-3 d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>
                                        iCloudsoft DevOps Consulting offers a step-by-step
                                        roadmap for adopting DevOps, including
                                    </h3>
                                    <p>
                                        <ul>
                                            <li>Understanding your requirements and analyzing your business goals</li>
                                            <li>Conducting a full audit of your IT environment and submit an assessment</li>
                                            <li>Assessing the feasibility of implementing DevOps at the project level</li>
                                            <li>Suggest best-fit DevOps implementation models</li>
                                            <li>Recommend ideal DevOps tools and guide integration of toolchain</li>
                                            <li>Estimate costs</li>
                                            <li>Train your in-house DevOps people & set out KPIs for specific projects</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/DevOps2.png")} alt='DevOps' title='DevOps' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-lg-5 pb-5 '>
                        <div className='row d-flex justify-content-between align-items-center g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>
                                        iCloudsoft also provides end-to-end expertise
                                        across the DevOps toolchain, including
                                    </h3>
                                    <p>
                                        <ul>
                                            <li><b>VIRTUALIZATION :</b> AWS, Azure, GCP </li>
                                            <li><b>ORCHESTRATON :</b> AWS CloudFormation, Kubernetes, Docker Swarm, Apache Mesos, Atlas </li>
                                            <li><b>CONTINUOUS INTEGRATION :</b> GitLab, Jenkins, Apache Maven </li>
                                            <li><b>AUTOMATION :</b> Apache JMeter, SonarQube, ReSharper, QASymphony </li>
                                            <li><b>CONTAINERIZATION :</b> Docker, Amazon EC2, rkt, LXC, Vagrant </li>
                                            <li><b>MONITORING & LOGS :</b> AWS CloudWatch, ELK Stack, New Relic, AppDynamics, Sensu </li>
                                            <li><b>SECURITY :</b> Snort, Vault, Sonatype, Veracode, Threat Stack </li>
                                            <li><b>COLLABORATION :</b> Jira, Confluence, FishEye, Crucible, HipChat, Slack </li>
                                            <li><b>ARTIFACT MANAGEMENT :</b> Artifactory, Git, Bitbucket, Mercurial, Perforce </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100' style={{ borderRadius: "10px" }} src={require("../Img/DevOps3.png")} alt='DevOps' title='DevOps' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Benefit from our expertise
                            </h2>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                On-Going Optimization
                                            </h2>
                                            <p>
                                                Our approach of continuous improvement (using proven CI/CD processes)
                                                minimizes time and cost of development
                                                and maximizes your ROI from your investments.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Deployment
                                            </h2>
                                            <p>
                                                Engaging with iCloudsoft brings benefits like fast testing
                                                and deployment.You will no longer need to schedule releases,
                                                we use DevOps tools to automate tests and releases,
                                                making your feedback cycle faster and more efficient.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                360 Degree Services
                                            </h2>
                                            <p>
                                                iCloudsoft's expertise across the DevOps toolchain has
                                                you covered for any eventuality.From continuous monitoring
                                                to automated configuration to infrastructure security,
                                                we offer comprehensive services.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Dedicated Experts
                                            </h2>
                                            <p>
                                                Our DaaS gives you access to experienced DevOps engineers
                                                whose expertise ensures your project is on track from start
                                                to finish.Access to our team of professionals can
                                                also help bring your IT department
                                                up to speed on the latest tools and systems.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DevOps