import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./GoogleWorkspace.css"
import { Helmet, HelmetProvider } from "react-helmet-async";

const GoogleWorkspace = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/googleworkspace" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology, Google Cloud WorkSpace" />
                    <title> Google Cloud Partner |  Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>


            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Your Google Workspace partner India
                        </h2>
                        <h1>
                            Stay connected anywhere anytime with Google Workspace
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Google Workspace </h3>
                                    <p>
                                        Google Workspace, previously known as G-Suite, is a collection
                                        of collaborative and productivity tools designed for businesses
                                        of all sizes. These tools cater to the unique productivity needs
                                        of every organization. To boost productivity and streamline operations,
                                        organizations can identify their specific needs and
                                        use the appropriate tools available within Google Workspace.
                                    </p>
                                    <p>
                                        With its various features, Google Workspace offers numerous
                                        benefits, including easy collaboration, seamless document
                                        management, enhanced security features, and accessibility
                                        on multiple devices. Migrate to Google Workspace today and experience
                                        the ultimate productivity suite for your business.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='GoogleWorkspace' title='GoogleWorkspace' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Key features of Google Workspace
                            </h2>
                            <p>
                                iCloudsoft Technology will explore the key features and benefits of Google
                                Workspace, as well as provide insights on how <br />
                                to leverage its tools effectively to meet your collaboration needs.
                            </p>
                        </div>
                        <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >


                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW1.png")} alt="CollaborateEffortlessly" title='CollaborateEffortlessly' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Collaborate Effortlessly
                                            </h2>
                                            <p>
                                                Google Workspace provides tools like Google Sheets, Docs,
                                                and Drive that make collaboration easy. With these tools,
                                                anyone can share their research data and collaborate in real-time,
                                                which helps to accelerate productivity.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW2.png")} alt="StreamlineTaskManagement" title='StreamlineTaskManagement' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Streamline Task Management
                                            </h2>
                                            <p>
                                                Google Sheets can help managers streamline task management
                                                by merging data and scheduling tasks using Google Calendar.
                                                This makes it easy for team members to access their
                                                assigned tasks and collaborate more efficiently.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW3.png")} alt="ProtectSensitiveMode" title='ProtectSensitiveMode' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Protect Sensitive Mode
                                            </h2>
                                            <p>
                                                Gmail's confidential mode is a useful tool for helping to protect
                                                sensitive information from unauthorized or accidental sharing.
                                                With this feature, users can send emails that expire after a
                                                certain time, revoke access to sent emails,
                                                or require a passcode to view the content.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW4.png")} alt="OfflineMode" title='OfflineMode' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Offline Mode
                                            </h2>
                                            <p>
                                                Google Workspace offers an offline mode that allows users to work
                                                without an internet connection. This means that you can continue
                                                working on your documents, sheets, and slides even when
                                                you don't have an internet connection.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW5.png")} alt="SecurityAndCompliance" title='SecurityAndCompliance' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Security and Compliance
                                            </h2>
                                            <p>
                                                Google Workspace enables administrators to configure compliance
                                                rules based on organizational requirements, and scan all incoming
                                                and outgoing emails to ensure compliance. In case of any violations,
                                                appropriate actions can be taken to mitigate the risks.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW6.png")} alt="SmartCompose" title='SmartCompose' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Smart Compose
                                            </h2>
                                            <p>
                                                Gmail's Smart Compose feature provides users with text suggestions
                                                that can help save time and effort when composing emails. With this
                                                feature, Gmail users can quickly and easily
                                                compose emails with minimal typing needed.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW7.png")} alt="LargeFileSharing" title='LargeFileSharing' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Large File Sharing
                                            </h2>
                                            <p>
                                                Files by Google is an Android app that enables peer-to-peer (P2P) sharing of files
                                                and apps without internet connectivity,
                                                ideal for areas with limited internet or quick file sharing between nearby devices.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className=''>
                                            <img className='' src={require("../Img/GW8.png")} alt="UnlimitedStorage" title='UnlimitedStorage' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Unlimited Storage
                                            </h2>
                                            <p>
                                                G Suite Business provides each user with unlimited storage for
                                                Gmail messages, Google Photos, and files in Drive, enabling
                                                organizations to store and manage large amounts
                                                of data without worrying about running out of space.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container-lg FeatureMain'>
                        <div className='GoogleWorkspaceCollaboration Dosis'>
                            <h2>
                                Google Workspace for all your collaboration needs
                            </h2>
                            <p>
                                From email and video conferencing to document creation and file storage,
                                Google Workspace offers a range of integrated applications that facilitate
                                seamless collaboration among team members,
                                whether they are working together in the office or remotely.
                            </p>
                        </div>
                        <div className='row text-center g-4 pb-5' >
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop' >
                                    <div>
                                        <img className='' src={require("../Img/gmail.png")} alt="gmail" title='Gmail' />
                                    </div>
                                    <h4>Gmail</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/meet.png")} alt="Meet" title='Meet' />
                                    </div>
                                    <h4>Meet</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/chat.png")} alt="Chat" title='Chat' />
                                    </div>
                                    <h4>Chat</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/calendar.png")} alt="calendar" title='Calendar' />
                                    </div>
                                    <h4>Calendar</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/drive.png")} alt="drive" title='drive' />
                                    </div>
                                    <h4>Drive</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/apps_script.png")} alt="apps_script" title='apps_script' />
                                    </div>
                                    <h4>Apps Script</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/docs.png")} alt="docs" title='docs' />
                                    </div>
                                    <h4>Docs</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/sheets.png")} alt="sheets" title='sheets' />
                                    </div>
                                    <h4>Sheets</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/currents.png")} alt="currents" title='currents' />
                                    </div>
                                    <h4>Currents</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/cloud_search.png")} alt="cloud_search" title='cloud_search' />
                                    </div>
                                    <h4>Cloud Search</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/sites.png")} alt="sites" title='sites' />
                                    </div>
                                    <h4>Sites</h4>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                                <div className='CollaborationNeedsTop'>
                                    <div>
                                        <img src={require("../Img/keep.png")} alt="keep" title='keep' />
                                    </div>
                                    <h4>Keeps</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " >
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Package
                            </h2>
                            {/* <p>
                                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia con sequuntur <br />
                                magni dolores eos qui ratione
                            </p> */}
                        </div>
                        <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                STARTER
                                            </h2>
                                            <p>
                                                <ul className='text-start'>
                                                    <li>Custom and secure business email</li>
                                                    <li>Hold video meetings with up to 100 participants and recording</li>
                                                    <li>30 GB cloud storage per user</li>
                                                    <li>Security and management controls</li>
                                                    <li>Standard Support</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                VALUE
                                            </h2>
                                            <p>
                                                <ul className='text-start'>
                                                    <li>Customized and secure email for your business</li>
                                                    <li>Hold video meetings with up to 150 participants and recording</li>
                                                    <li>Get 2 TB of cloud storage per user</li>
                                                    <li>Control your security and management settings</li>
                                                    <li>Standard Support / Enhanced Support.</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                PRO
                                            </h2>
                                            <p>
                                                <ul className='text-start'>
                                                    <li>Custom and secure business email + eDiscovery, retention</li>
                                                    <li>250 participant video meetings + recording, attendance tracking</li>
                                                    <li>5 TB cloud storage per user</li>
                                                    <li>Standard Support</li>
                                                    <li>Enhanced security and management controls, including Vault and advanced endpoint management</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='GoogleWorkplaceKeyFeatures'>
                                    <div className='GoogleWorkplaceKeyFeaturesMain'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                PREMIUM
                                            </h2>
                                            <p>
                                                <ul className='text-start'>
                                                    <li>Custom and secure business email + eDiscovery, retention, S/MIME encryption</li>
                                                    <li>250 participant video meetings + recording, attendance tracking, noise cancellation, in-domain live streaming</li>
                                                    <li>Flexible storage options</li>
                                                    <li>Enhanced Support</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default GoogleWorkspace