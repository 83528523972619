import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PrivateCloud = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/privateCloud" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Google Cloud Platform,Amazon Web Services,Microsoft,Hybrid Cloud,Backup" />
                    <title>Private Cloud | Cloud Solutions Provider in Pune</title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Private Cloud consulting services
                        </h2>
                        <h1>
                            Your cost-effective journey to the Cloud
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Optimizing your Private Cloud infrastructure</h3>
                                    <p>
                                        A private cloud server utilizes virtualization technology to consolidate
                                        computing resources from multiple servers in a secure and isolated
                                        environment. By providing IT resources to a single organization or
                                        group of users using private infrastructure, private cloud computing
                                        offers flexibility, cost savings, security, and control. For businesses
                                        with predictable workloads, customization requirements, or those operating in regulated
                                        industries, private cloud solutions are particularly beneficial.
                                    </p>
                                    <p>
                                        At iCloudsoft, we provide private cloud consulting services
                                        to guide you through the process of implementing a private cloud
                                        infrastructure that is tailored to your specific business needs.
                                        Our experienced professionals work closely with you to understand
                                        your legacy IT infrastructure and identify opportunities to
                                        optimize and deploy private cloud resources effectively.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='Private_Cloud' title='Private_Cloud' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                Benefits of Private Cloud
                            </h2>
                            <p>
                                The numerous benefits that private cloud solutions provide, including
                                improved security, cost savings, performance, customization, compliance
                                advantages, and robust disaster recovery capabilities. Understanding the
                                advantages of private cloud can help organizations make informed decisions about their
                                IT infrastructure and align technology with their business goals.
                            </p>
                        </div>
                        <div className="row p-lg-3 mb-4 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Security and Privacy:
                                            </h2>
                                            <p>
                                                Private cloud consulting services offer greater security and
                                                privacy compared to public clouds, through features such as
                                                dedicated leased lines and on-premise or data center hosting.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Reliability:
                                            </h2>
                                            <p>
                                                The virtualized operating environment in a private cloud makes
                                                the network more resilient to failures of the physical
                                                infrastructure, leading to greater reliability.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Control:
                                            </h2>
                                            <p>
                                                A private cloud provides greater control and the ability
                                                to personalize a solution,
                                                as it can be tailored to a specific organization's needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Customization:
                                            </h2>
                                            <p>
                                                Private clouds can be customized to fit specific
                                                business needs and requirements.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Compliance:
                                            </h2>
                                            <p>
                                                Organizations in regulated industries can benefit from
                                                private clouds as they allow for
                                                greater compliance with industry-specific regulations.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Scalability:
                                            </h2>
                                            <p>
                                                Private clouds offer the ability to scale resources up or down based on changing
                                                business needs, allowing for greater agility and flexibility.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Cost efficiency:
                                            </h2>
                                            <p>
                                                Private cloud consulting services can be more cost-efficient
                                                than traditional on-premise environments, and in some organizations,
                                                they can even be cheaper than public clouds.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Hybrid cloud readiness:
                                            </h2>
                                            <p>
                                                Private clouds are easily integrated with public clouds,
                                                allowing for cloud bursting in times of high demand. This
                                                enables the shifting of non-sensitive functions to a
                                                public cloud, freeing up more private cloud resources.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg FeatureMain ">
                        <div className='SolutionTop mt-lg-4 mb-lg-4'>
                            <h2>
                                Our Private Cloud expertise
                            </h2>
                        </div>
                        <div className="row mt-3 pb-3 g-4">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 ">
                                <div className='GooglePlatformMain'>
                                    <div className='Gcp-Content'>
                                        <h2>
                                            Strategy & Consulting
                                        </h2>
                                        <p>
                                            At iCloudsoft, we offer Private Cloud consulting services
                                            to help you harness the full potential of this powerful
                                            technology. Our team of cloud experts uses proven methodologies
                                            to assess your business needs and design
                                            a custom strategy for your Private Cloud implementation.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className='GooglePlatformMain'>
                                    <div className='Gcp-Content'>
                                        <h2>
                                            Cost Optimisation
                                        </h2>
                                        <p>
                                            Our private cloud consulting services focus on identifying
                                            cost-saving opportunities by optimizing the utilization of IT
                                            resources, reducing infrastructure management costs, and
                                            consolidating hardware. Our experts speed up the
                                            adoption of the latest technology by
                                            leveraging the private cloud with an optimum cost approach.'
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <div className='GooglePlatformMain'>
                                    <div className='Gcp-Content'>
                                        <h2>
                                            Private Cloud Migration
                                        </h2>
                                        <p>
                                            Our private cloud experts assess your business needs and
                                            guide you through a smooth transition to a private cloud
                                            environment. We take a meticulous approach to minimize downtime,
                                            mitigate risks and ensure data security during migration. Our
                                            team ensures that your private cloud is delivered on time,
                                            within budget and meets all business requirements.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PrivateCloud