import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Aws.css"
import { Helmet, HelmetProvider } from "react-helmet-async";

const Aws = () => {

    const form = useRef();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.email = "";

            var url = "https://wa.me/9527468898?text="
                + " Email : " + email;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setEmail("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        return errors;

    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://icloudsoft.in/solutions/amazonwebservices" />
                    <meta name="keywords" content="Cloud Solution Company In Pune, iCloudsoft Technology,Amazon Web Services" />
                    <title>  AWS Partner | Cloud Solutions Provider in Pune </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid CommonSize" style={{ backgroundImage: `url(${require("../Img/ContactBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', }}>
                    <div className="container-lg CommonTitle">
                        <h2>
                            Your AWS consulting partner India
                        </h2>
                        <h1>
                            Stay connected anywhere anytime with Google Workspace
                        </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-5 pb-5'>
                        <div className='row d-flex justify-content-between g-4'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3> AWS</h3>
                                    <p>
                                        iCloudsoft enables businesses to operate on the
                                        Amazon Web Services (AWS) cloud platform, providing
                                        them with a reliable, secure, and scalable
                                        infrastructure for their applications and services.
                                    </p>
                                    <p>
                                        iCloudsoft has 6+ years of experience in change management
                                        & digital transformations on AWS. They offer extensive
                                        cloud-based services for organizational development and have a
                                        strong technical background in the AWS platform.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-12'>
                                <div>
                                    <img className='w-100 ' src={require("../Img/Ceo.jpg")} alt='AWS' title='AWS' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-5 " style={{ backgroundColor: "#f3f2f0" }}>
                    <div className="container-lg p-lg-4 pt-2">
                        <div className='WhatMakes Dosis'>
                            <h2>
                                What is AWS?
                            </h2>
                            <p>
                                AWS (Amazon Web Services) is a cloud computing platform that offers a wide range of services such as database, computing, storage, content delivery, and networking to individuals and organizations.
                            </p>
                        </div>
                        <div className="row p-lg-3 d-flex justify-content-center align-items-center text-center laila-semibold">
                            <Carousel responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                partialVisible={false}
                                showDots={true}
                            >


                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/IncreaseGlobalPresence1.png")} alt="IncreaseGlobalPresence" title='IncreaseGlobalPresence1' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Increase Global Presence
                                            </h2>
                                            <p>
                                                AWS has one of the largest numbers of data centers all over the
                                                world extending through 25 different geographic regions.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/Widerange.png")} alt="Widerange" title='Widerange' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Wide range of products
                                            </h2>
                                            <p>
                                                AWS (AmazonWebServices) has more than 200 and minimum 200
                                                services for various use cases concerning any individual.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/Securearchitecture.png")} alt="Securearchitecture" title='Securearchitecture' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Secure architecture
                                            </h2>
                                            <p>
                                                With 6,500 government agencies using AWS, it has one of the most
                                                secure architectures to prevent any DDOS attack.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/FlexiblePricing.png")} alt="FlexiblePricing" title='FlexiblePricing' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Flexible Pricing
                                            </h2>
                                            <p>
                                                With pay-as-you-go and long-term committed pricing models customers
                                                get the flexibility to pay according to their usage.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/AutoScalability.png")} alt="AutoScalability" title='AutoScalability' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Auto Scalability
                                            </h2>
                                            <p>
                                                AWS offers unlimited scalability according to the demand and can
                                                be automated for minimum involvement of admin.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='AwsMain'>
                                    <div className='AwsMainSub'>
                                        <div className='AwsMainImg'>
                                            <img className='' src={require("../Img/DeployApplications.png")} alt="DeployApplications" title='DeployApplications' />
                                        </div>
                                        <div className='GoogleWorkplaceKeyFeatures-Content'>
                                            <h2>
                                                Ready to deploy applications
                                            </h2>
                                            <p>
                                                AWS marketplace has over 2,700 products ready to be deployed without
                                                any infrastructure management.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-md-none d-none d-lg-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg Dosis'>
                        <div className='row g-4 d-flex justify-content-around align-items-center g-4'>
                            <div className='col-lg-5 '>
                                <div>
                                    <img className='w-100' src={require("../Img/couple.png")} alt='news' title='news' />
                                </div>
                            </div>

                            <div className='col-lg-6 '>
                                <div className='CompanyPackageTop'>
                                    <h6>
                                        Don’t miss any updates and news from us
                                    </h6>
                                    <h2>
                                        Subscribe your Email address for latest updates
                                    </h2>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-0">

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                    </div>


                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-start mb-3 '>
                                            <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid  d-lg-none d-md-block' style={{ backgroundImage: `url(${require("../Img/searching-bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                    <div className='container-lg pb-5 p-4 Dosis'>
                        <div className='col-lg-12 '>
                            <div className='CompanyPackageTop'>
                                <h6>
                                    Don’t miss any updates and news from us
                                </h6>
                                <h2>
                                    Subscribe your Email address for weekly newsletter
                                </h2>
                            </div>
                            <form ref={form} onSubmit={loginHandle} className="row g-0">
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <input type="email" placeholder='Email address..' className="form-control CompanyEmailArea" id="inputEmail4" name="email"
                                        onChange={e => setEmail(e.target.value)} value={email}
                                    />
                                    {errors.email && <div className='CommonErrors'>{errors.email}</div>}
                                </div>

                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className='d-flex justify-content-start mb-3 '>
                                        <button type="submit" className="SubscribeButton"  ><b className='' style={{ fontWeight: "500" }}>Submit</b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className='container-lg pt-lg-5'>
                        <div className='row d-flex justify-content-between g-4 p-3'>
                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Amazon Lightsail</h3>
                                    <p>
                                        Amazon Lightsail is a cloud platform offered by AWS,
                                        designed to provide a cost-effective and easy-to-use
                                        solution for simpler workloads. It offers a fast
                                        and reliable environment, making it ideal for
                                        quick deployments and getting started with AWS.
                                    </p>
                                    <p>
                                        <ul className='p-3'>
                                            <li>Cost-effective cloud platform</li>
                                            <li>Easy-to-use interface</li>
                                            <li>Ideal for simpler workloads and quick deploymentsReliable and fast</li>
                                            <li>Best for simple web applications, dev/test environments, websites (including custom code, WordPress,
                                                and eCommerce), and single-server business software.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 p-lg-3'>
                                <div className='CompanyCEO'>
                                    <h3>Amazon EC2</h3>
                                    <p>
                                        Amazon EC2 is a compute web service that offers secure,
                                        resizable compute capacity in the cloud. It is designed for
                                        scalable deployments and optimizing your workloads.
                                    </p>
                                    <p>
                                        <ul className='p-3'>
                                            <li>Enterprise applications</li>
                                            <li>Migration from on-premises environments, including BYOL</li>
                                            <li>Application modernization.</li>
                                            <li>High-performance computing, Big Data, and Analytics workloads (such as Hadoop and Spark)</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Aws